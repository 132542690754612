/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect} from "react";
import { useSelector } from 'react-redux';
import { AuthContext } from "./AuthContext";
import { logout } from "../middlewares/auth";
import Swal from "sweetalert2";

let interval;
let refreshTokenAttempt = false; // Variable para controlar el intento de refrescar el token

export const AuthProvider = (props) => {
    const { store, children, reducerKey = "authReducer" } = props;

    if (!store) {
        return null;
    }

    const { dispatch, getState, subscribe } = store;
    const validateSession = useSelector(state=> state.loadingState.validateSession);
    const state = getState();
    const sessionSelector = (state) =>
        state[reducerKey] && state[reducerKey].session;
    const isAuthenticatedSelector = (state) =>
        state[reducerKey] && state[reducerKey].isAuthenticated;

    const [session, setSession] = useState(sessionSelector(state));
    const [isAuthenticated, setIsAuthenticated] = useState(isAuthenticatedSelector(state));
    const [showAlert, setShowAlert] = useState(false);
    const expirationTime = parseInt(process.env.REACT_APP_SESSION_EXPIRES);
    const [sessionTime, setSessionTime] = useState(new Date().getTime() + expirationTime);
    const token = localStorage.getItem('token');    
    const [hasRefreshedToken, setHasRefreshedToken] = useState(false);
    const reduxRefresTokenValidate = useSelector(state=> state.auth.validateRefreshToken)
        

    const signIn = (payload) =>
        dispatch({
            type: "SIGN_IN",
            payload
        });

    const signOut = () =>
        dispatch({
            type: "SIGN_OUT",
            payload: {}
        });
    
    const log_Out = () =>
        dispatch({
            type: "LOG_OUT",
            payload: localStorage.getItem('refreshToken')
        });
    
    const refreshingToken = () => {
        if (!hasRefreshedToken && !refreshTokenAttempt) {
            refreshTokenAttempt = true; // Establecer el intento de refrescar el token
            dispatch({
                type: "REFRESH_TOKEN",
                payload: localStorage.getItem('refreshToken')
            });
            setHasRefreshedToken(true);
            setTimeout(() => refreshTokenAttempt = false, 1000); // Resetear el intento después de 1 segundo
        }
    };


    const handleUserActivity = () => {
        const newExpirationTime = new Date().getTime() + expirationTime;
        setSessionTime(newExpirationTime);
        setShowAlert(false);
        setHasRefreshedToken(false);
    };

    useEffect(() => { 
        if(!token) return;
        if(!reduxRefresTokenValidate){
            if(interval!==undefined)clearInterval(interval);
            return;
        }
         interval = setInterval(() => {
            if(!validateSession){
                clearInterval(interval);
                handleUserActivity();
                refreshingToken(); 
                setHasRefreshedToken(true);           
                return;                
                
              }
           const currentTime = new Date().getTime();

            if (currentTime >= sessionTime) {
                clearInterval(interval);
                logout();
                signOut();                
                Swal.fire(
                    "Session Closed!",
                    "Hemos cerrado tu sesión por inactividad.",
                    "info"
                    ).then((res) => {
                        if (res.isConfirmed || res.isDismissed)
                        window.location.href = "/";
                    });                                
            } else if (sessionTime - currentTime <= 120000) {
                setShowAlert(true);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [signOut, sessionTime, validateSession, reduxRefresTokenValidate]);

    useEffect(() => {
        if (!showAlert) return;
        Swal.fire(
            "Session to Expire!",
            "Su sesión se cerrará pronto, por favor de clic para continuar.",
            "warning"
        ).then((res) => {
            if (res.isConfirmed || res.isDismissed){                
                handleUserActivity();
                refreshingToken();
            }  
        });
    }, [showAlert]);

    useEffect(() => {
        const handleMouseMove = () => handleUserActivity();
        const handleKeyPress = () => handleUserActivity();

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('keypress', handleKeyPress);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('keypress', handleKeyPress);
        };
    }, []);

    useEffect(() => {
        const unsubscribe = subscribe(() => {
            const newState = getState();
            const newSession = sessionSelector(newState);
            const newIsAuthenticated = isAuthenticatedSelector(newState);
            if (JSON.stringify(newSession) !== JSON.stringify(session)) {
                setSession(newSession);
            }

            if (newIsAuthenticated !== isAuthenticated) {
                setIsAuthenticated(newIsAuthenticated);
            }
        });
        return () => unsubscribe();
    }, [getState, sessionSelector, isAuthenticatedSelector, subscribe, session, isAuthenticated]);

    const value = {
        session,
        isAuthenticated,
        signIn,
        signOut,
        showAlert,
        handleUserActivity,
        refreshingToken,
        log_Out
    };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};