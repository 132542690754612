import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import Select from "react-select";

import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import Grid from "../../components/Grid";
import formatDateWithCustomFormat, {FormatsDate} from "../../utils/formatDate";

const Swal = require("sweetalert2");

const CollectConfirm = ({
  reduxGetClients,
  clients,
  reduxGetLogisticsOperators,
  logisticsOperators,
  reduxGetUnconfirmed,
  unconfirmed,
  reduxGetCompanies,
  reduxResetUnconfirmedForm,
  reduxUploadFileUnconfirmed,
  fileUploaded,
  reduxConfirmCollection,
  confirmCollection,
}) => {
  
  const {
    register: registerSearch,
    handleSubmit: handleSubmitSearch,
    reset,
    setValue: setValueSearch,
    getValues: getValuesSearch,
  } = useForm({
    defaultValues: {
      from: null,
      to: null,
    },
  });

  const {
    register: registerUpload,
    handleSubmit: handleSubmitUpload,
    watch: watchUpload,
    formState: { errors: errorsUpload },
    reset: resetUpload
  } = useForm({});

  const {
    register: registerConfirmCollection,
    handleSubmit: handleSubmitConfirmCollection,
    formState: { errors: errorsConfirmCollection },
    reset: resetConfirmCollection,
  } = useForm({});

  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [showUpload, setShowUpload] = useState(false);
  const [showConfirmCollection, setShowConfirmCollection] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [showExportSearch, setShowExportSearch] = useState(false);

  const file = useRef({});
  file.current = watchUpload("file", "");

  const CustomDateRangePicker = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      className="form-control"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
      value={value}
      autoComplete="Off"
    />
  ));
  
  const renderTooltip = (title) => (
    <Tooltip id="button-tooltip">{title}</Tooltip>
  );

  const handleShowUpload = () => {
    setFileName(null);
    resetUpload();
    setFileError(null);
    setShowUpload(true);
  };

  const handleCloseUpload = () => {
    setFileName(null);
    resetUpload();
    setFileError(null);
    setShowUpload(false);
  };

  const onSubmitUpload = (data) => {
    const file = data.file[0];
    reduxUploadFileUnconfirmed({ file: file});
    fileUploaded = null;
    resetUpload();
    setFileName(null);
    setFileError(null);
    setShowUpload(false);
    setShowConfirmCollection(true);
  };

  const handleCloseConfirmCollection = () => {
    resetConfirmCollection();
    setShowConfirmCollection(false);
  };

  const onSubmitConfirmCollection = (data) => {
    let items = fileUploaded.validItems.map(a => a.trackingId);
    let confirmObj={
      confirmationType: +data.confirmationType,
      payMethod: data.method,
      comments: data.comments.toString().replace('\n', ' '),
      validItems: items,
      amountConciliation: fileUploaded.amountConciliation  
    }
    resetConfirmCollection();
    reduxConfirmCollection(confirmObj);
    setShowConfirmCollection(false);
  };

  const columns = [
    {
      title: "# Dirección",
      render: (rowData) => {
        return <span>{rowData.idAddress}</span>;
      },
      field: 'idAddress',
      searchable: true
    },
    {
        title: "Guía",
        render: (rowData) => {
          return <span>{rowData.trackingId}</span>;
        },
        field: 'trackingId',
        searchable: true
    },
    {
      title: "Valor recaudado",
      render: (rowData) => {
        return <span> {rowData.collection} </span>
      },
      field: 'colletion',
      searchable: true
    },
    {
        title: "Operador",
        render: (rowData) => {
          return <span>{rowData.operator}</span>;
        },
        field: 'operator',
        searchable: true
      },
    {
        title: "Ciudad",
        render: (rowData) => {
          return <span>{rowData.city}</span>;
        },
        field: 'city',
        searchable: true
    },
    {
      title: "Departamento",
      render: (rowData) => {
        return <span>{rowData.department}</span>;
      },
      field: 'department',
      searchable: true
    },
    {
      title: "Remitente",
      render: (rowData) => {
        return <span>{rowData.client}</span>;
      },
      field: 'sender',
      searchable: true
    },
    {
      title: "Estado de Confirmación",
      render: (rowData) => {
        return <span>{rowData.confirmationState}</span>;
      },
      field: 'confirmationState',
      searchable: false
    },
  ];
  
  useEffect(() => {
    reduxGetClients({
      includeAll: true,
    });
    reduxGetLogisticsOperators({
      includeAll: true,
    });
    reduxGetCompanies({
      page: 1,
      search: "",
      offset: 1000,
      includeAll: true,
    });
    reset();
    reduxResetUnconfirmedForm();
    setFromDate(null);
    setToDate(null);
  }, []);

  useEffect(() => {
    let filters = {
      page: currentPage,
      offset: offset,
      search: search,
    };
    if (fromDate && toDate) {
      filters = {
        ...filters,
        from: formatDateWithCustomFormat(fromDate, {format: FormatsDate.FULL24}),
        to: formatDateWithCustomFormat(toDate, {format: "YYYY-MM-DD 23:59:59"}),
      };
    }
    if (
      getValuesSearch("senderIds") != "" &&
      getValuesSearch("senderIds") != undefined
    ) {
      filters = {
        ...filters,
        senderIds: getValuesSearch("senderIds"),
      };
    }
    reduxGetUnconfirmed(filters);
   
  }, [currentPage]);

  useEffect(() => {
    if (file.current != null && file.current.length > 0) {
      let filename = file.current[0].name;
      setFileName(filename);
      if (filename.split('.').at(-1).toLowerCase() != 'csv'){
        Swal.fire({
          title: "Información",
          icon: "warning",
          html:`El archivo seleccionado "<b>${filename}</b>" no corresponde al tipo de archivo esperado`,
        },
       
        );
        setFileName('');
        resetUpload();
      }
    }
   
  }, [file.current]);

  useEffect(() => {
    let unconfirmedObj = unconfirmed.items == undefined ? [] :unconfirmed.items
    if (
      (unconfirmedObj!= undefined || unconfirmedObj!= []) && 
      (
        (fromDate && toDate)|| 
        (getValuesSearch("senderIds") != "" && getValuesSearch("senderIds") != undefined)||
        (getValuesSearch("operatorIds") != "" && getValuesSearch("operatorIds") != undefined)||
        (search != "" && search != undefined )
      )&&
      (unconfirmedObj.length > 0) 
    ) {
      setShowExportSearch(true)
    }else{
      setShowExportSearch(false)
    } 
  }, [unconfirmed, fromDate, toDate, getValuesSearch("senderIds"), getValuesSearch("operatorIds")]);

  useEffect(() => {
    if (confirmCollection != null || confirmCollection!= undefined ){

      Swal.fire("Confirmación",
      `${confirmCollection.confirmed.length} registros confirmados con éxito`,
      "success").then((result) => {
        if (result.isConfirmed) {
          let filters = {
            page: currentPage,
            offset: offset,
            search: search,
          };
          if (fromDate && toDate) {
            filters = {
              ...filters,
              from: formatDateWithCustomFormat(fromDate, {format: FormatsDate.FULL24}),
              to: formatDateWithCustomFormat(toDate, {format: "YYYY-MM-DD 23:59:59"}),
            };
          }
      
          if (
            getValuesSearch("senderIds") != "" &&
            getValuesSearch("senderIds") != undefined
          ) {
            filters = {
              ...filters,
              senderIds: getValuesSearch("senderIds"),
            };
          }
          reduxGetUnconfirmed(filters);
          reset();
          reduxResetUnconfirmedForm();
        }
      });
    }
  }, [confirmCollection]);
  
  useEffect(() => {
    if (fileUploaded) {
      if(fileUploaded.invalid > 0) {
        const token = window.localStorage.getItem("token");
        fetch(
          `${process.env.REACT_APP_API_URL}/api/v1/financial/exportInvalid`,
          {
            method: "POST",
            body: JSON.stringify(fileUploaded.invalidItems),
            headers: new Headers({
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            }),
          }
        )
        .then((response) => response.blob())
        .then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = `unconfirmed_invalids_${moment(new Date()).tz("America/Bogota").format("YYYYMMDD_hhmm")}.csv`;
          document.body.appendChild(a);
          a.click();
          a.remove();
        });
        console.warn(fileUploaded.invalidItems)
      }
    }
  }, [fileUploaded]);

  const download = () => {
    const token = window.localStorage.getItem("token");

    let _data = {
      from: fromDate ? formatDateWithCustomFormat(fromDate, {format: FormatsDate.FULL24}) : null,
      to: toDate ? formatDateWithCustomFormat(toDate, {format: "YYYY-MM-DD 23:59:59"}): null,
      senderIds: getValuesSearch("senderIds"),
      operatorIds: getValuesSearch("operatorIds"),
      page: currentPage,
      offset: offset,
      search: search ? search : "",
    };

    fetch(`${process.env.REACT_APP_REPORT_URL}/api/v1/financial/exportSearch`, {
      method: "POST",
      body: JSON.stringify(_data),
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.blob())
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = `unconfirmed_address_${moment(new Date()).tz("America/Bogota").format("YYYYMMDD_hhmm")}.csv`
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  const handleChangeMultiSelect = (type, value) => {
    if(type === 'Sender'){
      const newSenderIds = {
        ...getValuesSearch('senderIds'),
        value
      }
      setValueSearch("senderIds", String(newSenderIds.value.map(item => item.value)));
    }else if(type === 'Operator'){
      const newOperatorIds = {
        ...getValuesSearch('operatorIds'),
        value
      }
      setValueSearch("operatorIds", String(newOperatorIds.value.map(item => item.value)));
    }
  }
  
  const onSubmitSearch = (data) => {
    console.log(data);
    let filters = {
      page: currentPage,
      offset: offset,
      search: search,
    };
    if (fromDate && toDate) {
      if (toDate < fromDate) {
        Swal.fire(
          "Información",
          "La fecha inicial debe ser  menor a la fecha final , intenta de nuevo",
          "info"
        );
        return;
      }
      filters = {
        ...filters,
        from: formatDateWithCustomFormat(fromDate, {format: FormatsDate.FULL24}),
        to: formatDateWithCustomFormat(toDate, {format: "YYYY-MM-DD 23:59:59"}),
      };
    }
   
    if (data.senderIds != "" && data.senderIds) {
      filters = {
        ...filters,
        senderIds: data.senderIds,
      };
    }
    if (data.operatorIds != "" && data.operatorIds != undefined) {
      filters = {
        ...filters,
        operatorIds: data.operatorIds,
      };
    }
    reduxGetUnconfirmed(filters);
  };

  const handledChangeDatePicker = (dates) => {
    const [start, end] = dates;

    setFromDate(start);
    setToDate(end);

    setValueSearch(
      "from",
      moment(start).tz("America/Bogota").format("MM-DD-YYYY")
    );
    setValueSearch(
      "to",
      moment(end).tz("America/Bogota").format("MM-DD-YYYY")
    );
  };

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">Confirmación de recaudo</h1>
              </div>
              <p className="mb-4">
                Módulo para gestionar la confirmación de recaudo
              </p>
              <form onSubmit={handleSubmitSearch(onSubmitSearch)}>
                <div className="d-flex justify-content-between align-items-start my-4 px-1 flex-wrap">
                  <div className="d-flex">
                    <div className="search-field mr-2">
                      <label htmlFor="from" className="form-label">Desde - Hasta</label>
                      <DatePicker
                        selected={fromDate}
                        onChange={handledChangeDatePicker}
                        startDate={fromDate}
                        endDate={toDate}
                        maxDate={new Date()}
                        dateFormat="MM-dd-yyyy"
                        customInput={<CustomDateRangePicker />}
                        selectsRange
                      />
                    </div>
                    <div className="search-field mx-2">
                      <label htmlFor="senderIds" className="form-label">Remitente</label>
                      <Select
                        {...registerSearch("senderIds")}
                        isClearable={true}
                        isMulti
                        id="senderIds"
                        onChange={(value) => handleChangeMultiSelect('Sender', value)}
                        options={
                          clients && Object.keys(clients).length > 0
                            ? clients.items
                              .filter((f) => f.isActive)
                              .map((ele, key) => ({
                                value: ele.idCompany,
                                label: ele.description,
                              }))
                            : []
                        }
                        styles={{
                          valueContainer: (baseStyles) => ({...baseStyles, maxHeight:"3.5vw", overflowY: "auto" }),
                          control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap"})
                        }}
                      />

                    </div>
                    <div className="search-field mx-2">
                      <label htmlFor="operatorIds" className="form-label">Operador</label>
                      <Select
                        isClearable={true}
                        {...registerSearch("operatorIds")}
                        isMulti
                        id="operatorIds"
                        onChange={(value) => handleChangeMultiSelect('Operator', value)}
                        options={
                          logisticsOperators &&
                          Object.keys(logisticsOperators).length > 0 &&
                          logisticsOperators.items
                            .filter((f) => f.isActive === true)
                            .map((ele, key) => ({
                              value: ele.idCompany,
                              label: ele.description,
                            }))
                        }
                        styles={{
                          valueContainer: (baseStyles) => ({...baseStyles, maxHeight:"3.5vw", overflowY: "auto" }),
                          control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap"})
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-around align-self-end">
                    <Button variant="primary" className="mr-5" type="submit" id="btnSearch">Buscar</Button>
                    <div>
                      {showExportSearch && (<OverlayTrigger
                        placement="top"
                        delay={{ show: 50, hide: 50 }}
                        overlay={renderTooltip("Exportar")}>
                        <button
                          className="btn btn-success btn-circle mx-1"
                          type="button"
                          onClick={download} >
                          <i className="fas fa-download fa-sm"></i>
                        </button>
                      </OverlayTrigger>)}
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 50, hide: 50 }}
                        overlay={renderTooltip("Importar")}
                      >
                        <button
                          className="btn btn-primary btn-circle mx-1"
                          type="button"
                          onClick={handleShowUpload}
                        >
                          <i className="fas fa-upload fa-sm"></i>
                        </button>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
                <div className="card shadow mb-4">
                  <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Listado de direcciones
                    </h6>
                  </div>
                  <div className="card-body">
                    <Grid
                      cols={columns}
                      data={
                        unconfirmed &&
                          Object.keys(unconfirmed).length > 0
                          ? unconfirmed.items
                          : []
                      }
                      page={
                        unconfirmed &&
                          Object.keys(unconfirmed).length > 0
                          ? Number(unconfirmed.page)
                          : currentPage
                      }
                      total={
                        unconfirmed &&
                          unconfirmed.hasOwnProperty("total")
                          ? unconfirmed.total
                          : 0
                      }
                      pages={
                        unconfirmed &&
                          Object.keys(unconfirmed).length > 0
                          ? Number(unconfirmed.totalPages)
                          : 1
                      }
                      offset={offset}
                      onChangePage={(page) => setCurrentPage(page)}
                      onChangeRange={(value) => {
                        setOffset(value);
                        let filters = {
                          page: 1,
                          offset: value,
                          search: search,
                        };
                        if (fromDate && toDate) {
                          if (toDate < fromDate) {
                            Swal.fire(
                              "Información",
                              "La fecha inicial debe ser  menor a la fecha final , intenta de nuevo",
                              "info"
                            );
                            return;
                          }
                          filters = {
                            ...filters,
                            from: formatDateWithCustomFormat(fromDate, { format: FormatsDate.FULL24 }),
                            to: formatDateWithCustomFormat(toDate, { format: "YYYY-MM-DD 23:59:59" }),
                          };
                        }

                        if (
                          getValuesSearch("senderIds") != "" &&
                          getValuesSearch("senderIds") != undefined
                        ) {
                          filters = {
                            ...filters,
                            senderIds: getValuesSearch("senderIds"),
                          };
                        }
                        if (
                          getValuesSearch("operatorIds") != "" &&
                          getValuesSearch("operatorIds") != undefined
                        ) {
                          filters = {
                            ...filters,
                            operatorIds: getValuesSearch("operatorIds"),
                          };
                        }
                        reduxGetUnconfirmed(filters);
                      }}
                      defaultValue={search}
                      onChangeSearch={(value) => {
                        setSearch(value);
                        let filters = {
                          page: 1,
                          offset: offset,
                          search: value,
                        };
                        if (fromDate && toDate) {
                          if (toDate < fromDate) {
                            Swal.fire(
                              "Información",
                              "La fecha inicial debe ser  menor a la fecha final , intenta de nuevo",
                              "info"
                            );
                            return;
                          }
                          filters = {
                            ...filters,
                            from: formatDateWithCustomFormat(fromDate, { format: FormatsDate.FULL24 }),
                            to: formatDateWithCustomFormat(toDate, { format: "YYYY-MM-DD 23:59:59" }),
                          };
                        }

                        if (getValuesSearch("senderIds") != "" && getValuesSearch("senderIds") != undefined) {
                          filters = {
                            ...filters,
                            senderIds: getValuesSearch("senderIds"),
                          };
                        }
                        if (getValuesSearch("operatorIds") != "" && getValuesSearch("operatorIds") != undefined) {
                          filters = {
                            ...filters,
                            operatorIds: getValuesSearch("operatorIds"),
                          };
                        }
                        reduxGetUnconfirmed(filters);
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showUpload}
        onHide={handleCloseUpload}
        onEscapeKeyDown={handleCloseUpload}
        backdrop="static"
        keyboard={true}>
          <form onSubmit={handleSubmitUpload(onSubmitUpload)}>
            <Modal.Header closeButton>
              <Modal.Title>Importar archivo de confirmación</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="form-group col-md-12">
                  <div className="custom-file">
                    <input
                      type="file"
                      id="file"
                      name="file"
                      accept=".csv"
                      {...registerUpload("file", { required: true })}
                      className={`custom-file-input ${errorsUpload.file && "is-invalid"}`} />
                    <label className="custom-file-label" htmlFor="validatedCustomFile"  >
                      {fileError
                        ? fileError
                        : fileName
                        ? fileName
                        : "Seleccionar archivo..."}
                    </label>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseUpload}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit">
                Enviar
              </Button>
            </Modal.Footer>
          </form>
      </Modal>
      <Modal
        show={showConfirmCollection}
        onHide={handleCloseConfirmCollection}
        onEscapeKeyDown={handleCloseConfirmCollection}
        backdrop="static"
        keyboard={true}>
          <form onSubmit={handleSubmitConfirmCollection(onSubmitConfirmCollection)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirmación forma de pago</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="form-group col-md-12 m-0">
                  <div className="d-flex flex-column my-2 mx-1">
                    <label className="form-label"><b>Análisis de registros</b></label>
                    <div className="border rounded p-2">
                      <div style={{width: "100%"}} className="d-flex justify-content-between">
                        <p className="m-0">Válidos:</p>
                        <div className="d-flex align-items-center"  style={{width: "10em"}} >
                          <span className=" font-weight-bold">{`${fileUploaded.valid}`}</span>
                          <span className="small ml-1">Registros</span>
                        </div>
                      </div>
                      <div style={{width: "100%"}} className="d-flex justify-content-between">
                        <p className="m-0">No válidos:</p>
                        <div className="d-flex align-items-center"  style={{width: "10em"}} >
                          <span className="font-weight-bold">{`${fileUploaded.invalid}`}</span>
                          <span className="small ml-1">Registros</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center my-3 mx-1">
                    <label className="m-0"><b>Confirmación de:</b></label>
                    <div className="mx-2 d-flex align-items-center">
                      <label className="my-1" htmlFor="type1"><input {...registerConfirmCollection("confirmationType", { required: true })} className="mx-1" type="radio" id="type1" name="confirmationType" value={1} />Remitente</label>
                    </div>
                    <div className="mx-2 d-flex align-items-center">
                      <label className="my-1" htmlFor="type2"><input {...registerConfirmCollection("confirmationType", { required: true })} className="mx-1" type="radio" id="type2" name="confirmationType" value={2} />Operador</label>
                    </div>
                  </div>
                  <div className="d-flex flex-column my-2 mx-1">
                  <label htmlFor="operatorIds" className="form-label"><b>Cierre de conciliación</b></label>
                    <div className="border rounded p-2">
                      <div style={{width: "100%"}} className="d-flex justify-content-between">
                        <p className="m-0">Total conciliación:</p>
                        <span style={{width: "10em"}} className="font-weight-bold">{`$ ${fileUploaded.amountConciliation}`}</span>
                      </div>
                    </div>
                  </div>
                 <div className="row my-2 mx-1">
                    <label htmlFor="operatorIds" className="form-label"><b>Forma de pago</b></label>
                    <select 
                      {...registerConfirmCollection("method", { required: true })}
                      className={`form-control ${ errorsConfirmCollection.method && "is-invalid"}`} > 
                      <option value={''}>Seleccione</option>
                      <option value={1}>Efectivo</option>
                      <option value={2}>Cruce de Factura</option>
                      <option value={3}>Negociación</option>
                    </select>
                  </div>
                 <div className="row my-2 mx-1">
                    <label htmlFor="operatorIds" className="form-label"><b>Observaciones</b></label>
                    <textarea
                      {...registerConfirmCollection("comments", { required: true })}
                      className={`form-control ${ errorsConfirmCollection.comments && "is-invalid"}`}>
                    </textarea>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseConfirmCollection}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit" disabled={fileUploaded.amountConciliation > 0 ? "" : "disabled"}>
                Confirmar
              </Button>            
            </Modal.Footer>
          </form>
      </Modal>

    </div>

  );
};

const mapStateToProps = (state) => {
  return {
    clients: state.companyState.clients,
    logisticsOperators: state.companyState.logisticsOperators,
    unconfirmed: state.financialState.unconfirmed,
    companies: state.companyState.companies,
    fileUploaded: state.financialState.fileUploaded,
    confirmCollection: state.financialState.confirmCollection
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetClients: (payload) =>
      dispatch({
        type: 'FETCH_COMPANIESBYSHIPPER_REQUEST',
        value: payload,
      }),
      reduxGetLogisticsOperators: (payload) =>
      dispatch({
        type: 'FETCH_COMPANIESBYLOGISTICSOPERATOR_REQUEST',
        value: payload,
      }),
    reduxGetUnconfirmed: (payload) =>
      dispatch({
        type: "FETCH_UNCONFIRMED_REQUEST",
        value: payload,
      }),
    reduxGetCompanies: (payload) =>
      dispatch({
        type: "FETCH_COMPANIES_REQUEST",
        value: payload,
      }),
    reduxResetUnconfirmedForm: () =>
      dispatch({
        type: "RESET_UNCONFIRMED_FORM",
      }),
    reduxUploadFileUnconfirmed: (payload) =>
    dispatch({
      type: "UPLOAD_UNCONFIRMED_REQUEST",
      value: payload,
    }),
    reduxConfirmCollection: (payload) =>
    dispatch({
      type: "CONFIRM_COLLECTION_REQUEST",
      value: payload,
    }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectConfirm);
