import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import Select from 'react-select';
import Swal from "sweetalert2";

import { isNullOrUndefined } from "../../../utils/functionsUtils";
import { alertTypesEnum } from "../../../utils/enumsUtil";

const UpsertAlert = ({
    show,
    alertData,
    handleClose,
    reduxGetOneAlert,
    alert,
    reduxGetAlertTypes,
    alertTypes,
    reduxGetAlertSources,
    alertSources,
    reduxCreateAlert,
    reduxUpdateAlert,
}) => {
    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        setError,
        watch,
        reset,
    } = useForm({
        defaultValues: {
            alertType: '',
            alertSource: '',
            code: '',
            description: '',
            to: '',
            subject: '',
            template: '',
        }
    });

    const [showSubject, setShowSubject] = useState(true);

    const alertTypeWatcher = watch("alertType", null);

    const customStyles = {
        menu: (base) => ({ ...base, zIndex: 9999 }),
        valueContainer: (baseStyles) => ({ ...baseStyles, maxHeight: "3.5vw", overflowY: "auto" }),
        control: (baseStyles) => ({ ...baseStyles, flexWrap: "nowrap" })
    }

    useEffect(() => {
        if (show === false) return;
        initForm();
    }, [show])

    useEffect(() => {
        if (show === false) return;

        if (!isNullOrUndefined(alertData)) {
            reduxGetOneAlert({
                idAlert: alertData.idAlert
            })
        }
    }, [alertData, reduxGetOneAlert]);

    useEffect(() => {
        if (show === false) return;

        if (!isNullOrUndefined(alertTypeWatcher) && alertTypeWatcher.label !== alertTypesEnum.EMAIL) {
            setShowSubject(false);
            setValue("subject", '')
        } else {
            setShowSubject(true);
        }
    }, [alertTypeWatcher])

    useEffect(() => {
        if (!alert || show === false) return;

        setValue("alertType", { label: alert.alertType.code, value: alert.alertType.idAlertType })
        setValue("alertSource", { label: alert.alertSource.code, value: alert.alertSource.idAlertSource })
        setValue("code", alert.code)
        setValue("description", alert.description)
        setValue("to", alert.to)
        setValue("subject", alert.subject)
        setValue("template", alert.template)
    }, [alert])

    const initForm = () => {
        reset();
        reduxGetAlertTypes({
            page: 1,
            offset: 1000,
            search: '',
        });
        reduxGetAlertSources({
            page: 1,
            offset: 1000,
            search: '',
        });
    }

    const isValidTemplate = async (text, field, title) => {
        const pattern = RegExp(`{\\$_[A-Z]{1}_[A-z0-9]*}`, "g");

        const variables = Array.from(text.matchAll(pattern));
        if (variables.length === 0) {
            const res = await Swal.fire({
                title: `Validación de ${title}`,
                text: `No se han encontrado palabras clave para ${title}, ¿desea continuar?`,
                icon: "question",
                showDenyButton: true,
                denyButtonText: 'No',
                confirmButtonText: 'Si',
            });

            if (!res.isConfirmed) {
                setError(field, "Agregue las palabras clave")
                return false;
            }
        } else {
            const variableRequired = ['T', 'F'];
            const wrongVariables = [];
            variables.forEach(item => {
                const element = item[0];
                const chars = element.split('_');
                if (!variableRequired.includes(chars[1])) {
                    wrongVariables.push({ name: element, error: 'Parámetro requerido erróneo' });
                    return;
                }
            })

            if (wrongVariables.length == 0) return true;

            Swal.fire({
                title: `Validación de ${title}`,
                html:
                    `
                    <div class='d-flex flex-column'>
                    <p>Las siguientes palabras clave tienen problemas:</p>
                    
                    <table class='table table-sm table-responsive' style='max-height: 20vh'>
                        <thead>
                            <tr>
                                <th>Variable</th>
                                <th>Error</th>
                            </tr>
                        </thead>
                        <tbody class='overflow-y-auto'>
                        ${wrongVariables.map(e => (
                        `<tr>
                                <td>${e.name}</td>
                                <td>${e.error}</td>
                            </tr>`
                    )).join('')}
                        </tbody>
                    </table>
                    </div>
                    `,
                icon: "error",
            });
            setError(field, 'Solucione las palabras clave erroneas')
            return false;
        }
        return true;
    }

    const isValidTo = (text) => {
        let errors = false;
        if(isNullOrUndefined(text)) return true;
        switch (alertTypeWatcher.label) {
            case alertTypesEnum.EMAIL:
                const emailPattern = RegExp(/\S+@\S+\.\S+/);
                const emails = text.split(',') || [];
                emails.forEach(ele => {
                    const match = ele.match(emailPattern)
                    if (isNullOrUndefined(match)) {
                        errors = true;
                        return;
                    }
                });
                return errors ? 'Formato invalido' : true;
            default:
                const phoneNumbers = text.split(',') || [];
                phoneNumbers.forEach(ele => {
                    const match = isNaN(ele)
                    if (match) {
                        errors = true;
                        return;
                    }
                });
                return errors ? 'Formato invalido' : true;
        }
    }

    const submit = async (data) => {
        if (showSubject === true) {
            const res = await isValidTemplate(data.subject, "subject", "asunto");
            if (res === false) return;
        }

        const res = await isValidTemplate(data.template, "template", "plantilla");
        if (res === false) return;

        let dataObj = {
            idAlertType: data.alertType.value,
            idAlertSource: data.alertSource.value,
            description: data.description,
            to: data.to,
            subject: data.subject,
            template: data.template,
        }

        if (alert) {
            dataObj = { idAlert: alert.idAlert, ...dataObj };
            reduxUpdateAlert(dataObj);
        } else {
            dataObj = { code: data.code, ...dataObj };
            reduxCreateAlert(dataObj);
        }

        handleClose();
    }

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                onEscapeKeyDown={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <form onSubmit={handleSubmit(submit)}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="d-flex justify-content-between align-items-center">
                                {alert ? "Editar" : "Nueva"} alerta
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className='form-group col'>
                                <label
                                    htmlFor='alertType'
                                    className='form-label'>
                                    Tipo *
                                </label>
                                <Controller
                                    control={control}
                                    rules={{
                                        required: 'El código de alerta es requerido',
                                    }}
                                    name="alertType"
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                {...field}
                                                isClearable
                                                styles={customStyles}
                                                options={
                                                    alertTypes && Object.keys(alertTypes).length > 0
                                                        ? alertTypes.items
                                                            .map(e => ({
                                                                value: e.idAlertType,
                                                                label: e.code,
                                                            }))
                                                        : []
                                                }
                                            />
                                            {
                                                errors.alertType &&
                                                <span className="error-message">
                                                    {errors.alertType.message}
                                                </span>
                                            }
                                        </>
                                    )}
                                />
                            </div>
                            <div className='form-group col'>
                                <label
                                    htmlFor='alertSource'
                                    className='form-label'>
                                    Fuente *
                                </label>
                                <Controller
                                    control={control}
                                    name="alertSource"
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                {...field}
                                                isClearable
                                                styles={customStyles}
                                                options={
                                                    alertSources && Object.keys(alertSources).length > 0
                                                        ? alertSources.items
                                                            .map((ele, key) => ({
                                                                value: ele.idAlertSource,
                                                                label: ele.code,
                                                            }))
                                                        : []
                                                }
                                            />
                                            {
                                                errors.alertSource &&
                                                <span className="error-message">
                                                    {errors.alertSource.message}
                                                </span>
                                            }
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className='form-group col'>
                                <label
                                    htmlFor='code'
                                    className='form-label'>
                                    Código *
                                </label>
                                <Controller
                                    control={control}
                                    rules={{
                                        required: 'El código de alerta es requerido',
                                    }}
                                    name="code"
                                    render={({ field }) => (
                                        <>
                                            <input
                                                {...field}
                                                disabled={alertData ? true : false}
                                                className="form-control"
                                                type="text"
                                            />
                                            {
                                                errors.code &&
                                                <span className="error-message">
                                                    {errors.code.message}
                                                </span>
                                            }
                                        </>
                                    )}
                                />
                            </div>
                            <div className='form-group col'>
                                <label
                                    htmlFor='description'
                                    className='form-label'>
                                    Descripción
                                </label>
                                <Controller
                                    control={control}
                                    name="description"
                                    render={({ field }) => (
                                        <>
                                            <input className="form-control" {...field} type="text" />
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className='form-group col'>
                                <label
                                    htmlFor='to'
                                    className='form-label'>
                                    Destinatario
                                </label>
                                <Controller
                                    control={control}
                                    name="to"
                                    rules={{
                                        validate: isValidTo,
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <input className="form-control" {...field} type="text" />
                                            {
                                                errors.to &&
                                                <span className="error-message">
                                                    {errors.to.message}
                                                </span>
                                            }
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                        {
                            showSubject &&
                            <div className="row">
                                <div className="form-group col">
                                    <label htmlFor="subject" className="form-label">
                                        Asunto *
                                    </label>
                                    <Controller
                                        control={control}
                                        name="subject"
                                        rules={{
                                            required: {
                                                value: showSubject,
                                                message: "El asunto es obligatorio"
                                            }
                                        }}
                                        render={({ field }) => (
                                            <>
                                                <input
                                                    {...field}
                                                    className="form-control"
                                                    type="text" />
                                                {
                                                    errors.subject &&
                                                    <span className="error-message">
                                                        {errors.subject.message}
                                                    </span>
                                                }
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className='form-group col'>
                                <label
                                    htmlFor='template'
                                    className='form-label'>
                                    Plantilla *
                                </label>
                                <Controller
                                    control={control}
                                    name="template"
                                    rules={{
                                        required: 'La plantilla es obligatoria'
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <textarea
                                                {...field}
                                                className="form-control"
                                            />
                                            {
                                                errors.template &&
                                                <span className="error-message">
                                                    {errors.template.message}
                                                </span>
                                            }
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancelar
                        </Button>
                        <Button variant="primary" type="submit">
                            Guardar
                        </Button>
                    </Modal.Footer>
                </form >
            </Modal>
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        alert: state.alertState.alert,
        alertTypes: state.alertTypeState.alertTypes,
        alertSources: state.alertSourceState.alertSources,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetOneAlert: (payload) =>
            dispatch({
                type: "FETCH_ONE_ALERT_REQUEST",
                value: payload,
            }),
        reduxGetAlertTypes: (payload) =>
            dispatch({
                type: "FETCH_ALERT_TYPES_REQUEST",
                value: payload,
            }),
        reduxGetAlertSources: (payload) =>
            dispatch({
                type: "FETCH_ALERT_SOURCES_REQUEST",
                value: payload,
            }),
        reduxCreateAlert: (payload) =>
            dispatch({
                type: "CREATE_ALERT_REQUEST",
                value: payload,
            }),
        reduxUpdateAlert: (payload) =>
            dispatch({
                type: "UPDATE_ALERT_REQUEST",
                value: payload,
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpsertAlert);