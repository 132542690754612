import { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import moment from 'moment';
import Select from 'react-select';

import { buttonIcon, buttonTypes } from "../../../../utils/buttonsUtil";

import Sidebar from "../../../../components/Navigation/Sidebar";
import Topbar from "../../../../components/Navigation/Topbar";
import Header from "../../../../components/Page/header";
import Grid from '../../../../components/Grid';
import CollapsedSection from "../../../../components/CollapsedSection";
import NumberInput from "../../../../components/Inputs/Text/Number";
import MultiFile from "../../../../components/Inputs/MultiFile";
import { hasNoCountryLimitations } from "../../../../utils/functionsUtils";

const Return = ({
    successfulCreateManifest,
    errorsCreateManifest,
    createManifestResult,
    reduxGetReturns,
    returns,
    reduxGetLogisticsOperators,
    logisticsOperators,
    reduxGetBranches,
    branches,
}) => {
    const history = useHistory();
    const { idReturn } = useParams();
    const userData = JSON.parse(window.localStorage.getItem('userData'));
    const [currentPage, setCurrentPage] = useState(1);
    const [currentOffset, setCurrentOffset] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    const [operatorFilter, setOperatorFilter] = useState('');
    const userCountry = userData.idCountry;
    const viewAllCountriesData = hasNoCountryLimitations();
    const [dspFilter, setDspFilter] = useState('');

    const {
        handleSubmit: handleSubmitDetail,
        control: detailControl,
        formState: { errors: detailErrors },
        setError: setDetailError,
        reset: resetDetail,
    } = useForm({
        defaultValues: {
            manifestDetail: [],
        }
    });
    useEffect(() => {
        if (!userData.idHub) {
            Swal.fire(
                "No permitido",
                `Lo sentimos pero su usuario no está asociado a ningún HUB o el HUB no tiene asociada una bodega`,
                "error"
            );
            history.goBack()
            return;
        }
    }, [])
    
    useEffect(() => {
        if (userData.idHub) {
            reduxGetReturns({
                page: 1,
                offset: 10
            });
        }
    }, []);

    useEffect(() => {
        reduxGetReturns({
            page: currentPage || 1,
            offset: currentOffset || 10,
            search: searchQuery || '',
            idOperator: operatorFilter || ''
        });
    }, [currentPage, currentOffset, searchQuery, operatorFilter]);

    const {
        handleSubmit: handleAddEvidences,
        control: evidencesControl,
        formState: { errors: evidencesError },
        setValue: setEvidencesValue,
        getValues: getEvidencesValues,
        watch: evidencesWatch,
        reset: resetEvidences,
    } = useForm({
        defaultValues: {
            files: [],
            comments: '',
        }
    });


    const filteredData = useMemo(() => {
        if (!operatorFilter) return ;
        return returns.items.filter(item => item.idOperator === operatorFilter);
    }, [operatorFilter]);



    useEffect(() => {
        if (successfulCreateManifest) {
            Swal.fire(
                "Proceso Exitoso",
                `${createManifestResult.message}`,
                "success"
            );
        }
        if (errorsCreateManifest) {
            Swal.fire(
                "Proceso Fallido",
                `${createManifestResult.message}`,
                "error"
            );
        }

    }, [successfulCreateManifest, errorsCreateManifest, createManifestResult])

    useEffect(() => {
    }, [returns]);

    useEffect(() => {
        reduxGetLogisticsOperators({
            page: 1,
            offset: 100000,
            search: '',
            isActive: true,
        });
    }, []);

    useEffect(() => {
        reduxGetBranches({
            page: 1,
            offset: 100000,
            search: '',
        });
    }, []);

    const operatorOptions = useMemo(() => {
        const logisticsOperatorsMap = logisticsOperators?.items
            ?.filter(company => (viewAllCountriesData || company.country === userCountry.toString()))
            .map(company => ({
                value: company.idCompany,
                label: company.description || company.businessName,
                type: 'OPERATOR-LOG'
            })) || [];

        const dspOperators = branches?.items
            ?.filter(branch =>
                branch.idCountry.toString() === userCountry.toString() && 
                branch.dspOperator
            )
            .map(branch => ({
                value: branch.dspOperator.idCompany,
                label: branch.branchName || branch.dspOperator.description,
                type: 'DSP'
            }))
            .filter((v,i,a) => a.findIndex(t => t.value === v.value) === i) || [];
        
        return [
            {
                label: 'Operadores Logísticos',
                options: logisticsOperatorsMap
            },
            {
                label: 'DSP',
                options: dspOperators
            }
        ];
    }, [logisticsOperators, branches, userCountry]);

    const customFilters = (
        <div className="d-flex align-items-center mx-3">
            <label className="mr-2 mb-0">Operador:</label>
            <Select
                isClearable
                value={operatorFilter ? 
                    operatorOptions.flatMap(group => group.options)
                        .find(option => option.value === operatorFilter) 
                    : null
                }
                onChange={(selected) => {
                    setOperatorFilter(selected?.value || '');
                    setCurrentPage(1);
                }}
                options={operatorOptions}
                className="form-control-sm"
                classNamePrefix="select"
                placeholder="Seleccionar operador..."
                noOptionsMessage={() => "No hay operadores disponibles"}
                styles={{
                    control: (base) => ({
                        ...base,
                        minWidth: '300px',
                        height: '38px'
                    }),
                    menu: (base) => ({
                        ...base,
                        zIndex: 9999
                    }),
                    group: (base) => ({
                        ...base,
                        paddingTop: 8,
                        paddingBottom: 8
                    }),
                    groupHeading: (base) => ({
                        ...base,
                        fontSize: '0.9em',
                        fontWeight: 600,
                        color: '#4e73df',
                        textTransform: 'uppercase',
                        padding: '8px 12px'
                    })
                }}
                formatOptionLabel={({ label, type }) => (
                    <div style={{ 
                        display: 'flex', 
                        flexDirection: 'column'
                    }}>
                        <span>{label}</span>
                        <span style={{ 
                            fontSize: '0.75em',
                            color: '#858796',
                            marginTop: '2px'
                        }}>
                            {type === 'DSP' ? 'DSP' : 'Operador Logístico'}
                        </span>
                    </div>
                )}
            />
        </div>
    );

    const actionButtons = [
        {
            onClick: () => {
                history.push("/wms/inventory-movements");
            },
            description: "Regresar a Ingresos",
            buttonType: buttonTypes.Primary,
            buttonIcon: buttonIcon.Arrow_LeftReply,
        },

        {
            onClick: () => {
                history.push(`/wms/pending-returns/entry/`);
            },
            description: "Ingresar Devolución",
            buttonType: buttonTypes.Success,
            buttonIcon: buttonIcon.Sign,
        }

    ];

    const columns = [
        {
            title: "Operador",
            field: "operator.description",
            render: (item) => <span>{item.operator?.description}</span>,
            searchable: false,
            visible: true,
        },
        {
            title: "Código de Retorno",
            field: "idReturn",
            render: (item) => <span>RTN-{String(item.idReturn)}</span>,
            searchable: false,
            visible: true,
        },
        {
            title: "Estado",
            field: "status",
            render: (item) => (
                <span className={`badge ${getStatusBadgeColor(item.status)}`}>
                    {getStatusLabel(item.status)}
                </span>
            ),
            searchable: false,
            visible: true,
        },
        {
            title: "Fecha de Creación",
            field: "createdDate",
            render: (item) => <span>{moment(item.createdDate).format('DD/MM/YYYY')}</span>,
            searchable: false,
            visible: true,
        },
        {
            title: "Acciones",
            field: "actions",
            render: (item) => (
                <div className="d-flex align-items-center">
                    <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Procesar Devolución</Tooltip>}
                    >
                        <Button 
                            title="Procesar Devolución"
                            variant="primary"
                            size="sm"
                            className="btn-circle"
                            onClick={() => history.push(`pending-returns/process/${item.idReturn}`)}
                        >
                            <i className="fas fa-cogs fa-sm"></i>
                        </Button>
                    </OverlayTrigger>
                </div>
            ),
            searchable: false,
            visible: true,
            width: "180px",
            fixed: true,
            fixedPosition: "right",
            sticky: true,
            style: { zIndex: 100 }
        }
    ];

    // Agregar función para obtener la etiqueta del estado
    const getStatusLabel = (status) => {
        switch (status) {
            case 1:
                return 'Pendiente';
            case 2:
                return 'En Proceso';
            case 3:
                return 'Completado';
            case 4:
                return 'Cancelado';
            default:
                return 'Desconocido';
        }
    };

    const getStatusBadgeColor = (status) => {
        switch (status) {
            case 1:
                return 'bg-warning text-dark';
            case 2:
                return 'bg-info text-white';   
            case 3:
                return 'bg-success text-white'; 
            case 4:
                return 'bg-danger text-white';  
            default:
                return 'bg-secondary text-white';
        }
    };

    const handleChangePage = (newPage) => {
        setCurrentPage(newPage || 1);
    };

    const handleChangeRange = (newOffset) => {
        setCurrentOffset(newOffset || 10);
        setCurrentPage(1);
    };

    const handleChangeSearch = (query) => {
        setSearchQuery(query || '');
        setCurrentPage(1);
    };

    return (
        <>
            <div>
                <div id="wrapper">
                    <Sidebar />
                    <div id="content-wrapper" className="d-flex flex-column">
                        <div id="content">
                            <Topbar />
                            <div className="container-fluid">
                                <Header title={"Devoluciones Pendientes"} actionButtons={actionButtons} />
                            </div>
                            <div className="card shadow mb-4">
                                <div className="card-header py-3">
                                    <h6 className="m-0 font-weight-bold text-primary">
                                        Devoluciones
                                    </h6>
                                </div>
                                <div className="card-body d-flex flex-column flex-gap-1">
                                    <section
                                        sectionKey="manifestDetail"
                                        title="Detalle"
                                        show={true}
                                    >
                                        <div className="col-auto m-0 p-0">
                                            <form>
                                                <Controller
                                                    control={detailControl}
                                                    name='manifestDetail'
                                                    render={({ field }) => (
                                                        <>
                                                            <Grid
                                                                cols={columns}
                                                                data={returns?.items || []}
                                                                page={Number(returns?.page) || currentPage}
                                                                pages={returns?.totalPages || 1}
                                                                onChangeRange={handleChangeRange}
                                                                onChangePage={handleChangePage}
                                                                onChangeSearch={handleChangeSearch}
                                                                total={returns?.total || 0}
                                                                offset={currentOffset}
                                                                showRange={true}
                                                                showFilters={false}
                                                                enableFixedColumns={true}
                                                                stickyHeader={true}
                                                                actionsButton={actionButtons}
                                                                customFilters={customFilters}
                                                            />
                                                        </>
                                                    )}
                                                />
                                            </form>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        wmsOrderForReturn: state.warehouseState.wmsOrderForReturn,
        successfulCreateManifest: state.warehouseState.successfulCreateManifest,
        errorsCreateManifest: state.warehouseState.errorsCreateManifest,
        createManifestResult: state.warehouseState.createManifestResult,
        returns: state.warehouseState.returns,
        successfulFetchReturns: state.warehouseState.successfulFetchReturns,
        errorsFetchReturns: state.warehouseState.errorsFetchReturns,
        logisticsOperators: state.companyState.logisticsOperators,
        branches: state.branchState.branches,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetReturns: (payload) =>
            dispatch({
                type: "FETCH_WMS_RETURNS_REQUEST",
                value: payload
            }),
        reduxGetLogisticsOperators: (payload) =>
            dispatch({
                type: 'FETCH_COMPANIESBYLOGISTICSOPERATOR_REQUEST',
                value: payload,
            }),
        reduxGetBranches: (payload) =>
            dispatch({
                type: 'FETCH_BRANCHES_REQUEST',
                value: payload,
            }),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Return);