import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import { connect } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { ordersLockedActions } from "../../redux/reducers/OrdersLockedReducer";
import UploadOrdersLocked from "./UploadOrdersLocked";
import formatDateWithCustomFormat, { FormatsDate } from "../../utils/formatDate";
import Swal from "sweetalert2";

const FilterComponent = ({
    onSubmit,
    startDateState,
    endDateState,
    setEndDateFilter,
    setStartDateFilter,
    reduxOrderToConfirm,
    renderUploadBtn,
    ordersToConfirm
}) => {
    
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [guideOrExternalId, setGuideOrExternalId] = useState("1"); 
    const [orderLocked, setOrderLocked] = useState(""); 

    useEffect(() => {
        if (!startDateState && !endDateState) {
            setDateRange([null, null]);
        }
    }, [startDateState, endDateState]);

    useEffect(() => {
        if (ordersToConfirm != null) {          
            onSubmit();
        }
    }, [ordersToConfirm]);

    const handleChangeDates = (dates) => {
        const [start, end] = dates;
        const startDate = moment(start).tz("America/Bogota").format("YYYY-MM-DD");
        const endDate = moment(end).tz("America/Bogota").format("YYYY-MM-DD");
        const startDateFormatted = formatDateWithCustomFormat(startDate, {
            format: FormatsDate.FULL24,
        });
        const endDateFormatted = formatDateWithCustomFormat(endDate, {
            format: FormatsDate.FULL24,
        });
        setStartDateFilter(startDateFormatted);
        setEndDateFilter(endDateFormatted);
        setDateRange(dates);
    };

    const handleSubmit = () => {
        if (startDate && !endDate) {
            return Swal.fire('Valide el Filtro!', 'Debe seleccionar un rango de fechas.', 'warning');
        }
        onSubmit();
    };

    const handleSubmitInput = (e) => {
        e.preventDefault();

        if (!orderLocked.trim()) {
            return Swal.fire('Campo vacío', 'Ingrese un número de guía o ID externo.', 'warning');
        }

        reduxOrderToConfirm({
            orderLocked: orderLocked,
            guideOrExternalId: guideOrExternalId
        });

        setOrderLocked("");
        
    };

    return (
        <div className="d-flex mb-3 justify-content-between align-items-center">
            <Row className="align-items-end">
                <Col md="auto">
                    <label className='form-label'>Desde - Hasta</label>
                    <DatePicker
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        onChange={handleChangeDates}
                        dateFormat="yyyy-MM-dd"
                        className={'form-control'}
                        maxDate={new Date()}
                        shouldCloseOnSelect={false}
                        isClearable
                    />
                </Col>
                <Col md="auto">
                    <button className='btn btn-primary ms-2' onClick={handleSubmit}>Buscar</button>  
                </Col>
            </Row>
            
            {renderUploadBtn && (
                <div className="card ms-auto">
                    <div className="card-body">
                        <div className="d-flex align-items-center">
                            {/* Formulario con Input */}
                            <form className="me-3" onSubmit={handleSubmitInput}>
                                <div className="row g-3 align-items-center">
                                    <div className="col-auto">
                                        <div className="form-check form-check-inline">
                                            <input 
                                                id="uploadByGuia" 
                                                className="form-check-input" 
                                                type="radio" 
                                                value="1"
                                                checked={guideOrExternalId === "1"} 
                                                onChange={(e) => setGuideOrExternalId(e.target.value)}
                                            /> 
                                            <label className="form-check-label" htmlFor="uploadByGuia">Guía</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input 
                                                id="uploadByAddress" 
                                                className="form-check-input" 
                                                type="radio" 
                                                value="2"
                                                checked={guideOrExternalId === "2"} 
                                                onChange={(e) => setGuideOrExternalId(e.target.value)}
                                            /> 
                                            <label className="form-check-label" htmlFor="uploadByAddress">ID Externo</label>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Ingrese Guía o ID Externo"
                                            value={orderLocked}
                                            onChange={(e) => setOrderLocked(e.target.value)}
                                            onKeyDown={(e) => e.key === "Enter" && handleSubmitInput(e)}
                                            required
                                        />
                                    </div>
                                    <div className="col-auto">
                                        <button type="submit" className="btn btn-primary">Enviar</button>
                                    </div>
                                </div>                            
                            </form>
                            <div className="col-auto">                                
                                <div className="ms-3">
                                    <UploadOrdersLocked guideOrExternalId={guideOrExternalId} />
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    startDateState: state.ordersLocked.startDate,
    endDateState: state.ordersLocked.endDate,
    ordersToConfirm: state.ordersLocked.ordersToConfirm,
});

const mapDispatchToProps = (dispatch) => {
    return {
        setEndDateFilter: (payload) => {
            dispatch({
                type: ordersLockedActions.SET_END_DATE,
                value: payload
            });
        },
        setStartDateFilter: (payload) => {
            dispatch({
                type: ordersLockedActions.SET_START_DATE,
                value: payload
            });
        },
        reduxOrderToConfirm: (payload) => { 
            dispatch({
                type: 'FETCH_ORDERS_TO_CONFIRM',
                value: payload
            });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterComponent);
