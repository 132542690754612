
import React from "react";
import { Modal } from "react-bootstrap";

const GeoPermissionAlert = ({
    handleCloseModal,
    showGeoPermissionModal,
}) => {
    return (
        <>
            <Modal
                show={showGeoPermissionModal}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                className="geo-permission-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Permiso de ubicación
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="form-group">
                        <label className="form-label">
                            Para mejorar la experiencia de usuario, la aplicación requiere acceder a tu ubicación.
                        </label>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default GeoPermissionAlert;