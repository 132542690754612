import { Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Show } from "../../hooks/Show";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { DateTime, IANAZone } from "luxon";
import { isNullOrUndefined } from "../../utils/functionsUtils";

const UploadOrdersLocked = ({
    reduxUploadOrdersToConfirm,
    successUploadOrdersToConfirm,
    ordersUploaded,
    guideOrExternalId
}) => {
    const [showUpload, setShowUpload] = useState(false);
    const [fileError, setFileError] = useState(null);
    const [fileName, setFileName] = useState(null);
    
    const {
        register: registerUpload,
        handleSubmit: handleSubmitUpload,
        watch: watchUpload,
        formState: { errors: errorsUpload },
        reset: resetUpload,
    } = useForm({
        defaultValues: {
            guideOrExternalId: guideOrExternalId || '1'
        }
    });
    const file = useRef({});
    file.current = watchUpload('file', '');

    useEffect(() => {
        if (file.current != null && file.current.length > 0) {
          setFileName(file.current[0].name);
        }
    }, [file.current]);
    
    useEffect(() => {
        if (successUploadOrdersToConfirm) {
            if(ordersUploaded.invalidItems.length > 0) {
                requestUploadOrders();
            }

            Swal.fire(
                'Proceso exitoso!',
                'Se procesaron ' + ordersUploaded.processed + " de "+ ordersUploaded.total + ' registros del archivo cargado.',
                'success'
            );
        }
    }, [successUploadOrdersToConfirm]);

    const handleCloseUpload = () => {
        setFileName(null);
        resetUpload();
        setFileError(null);
        setShowUpload(false);
    };

    const onSubmitUpload = (data) => {
        const tempFile = data.file[0];
        if (!tempFile || tempFile.type !== 'text/csv') 
            return Swal.fire('Archivo errado', 'El archivo no cumple con el formato establecido <br/> Señor usuario el archivo debe tener el formato \'CSV UTF-8 delimitado por comas\'', 'error');
        
        const reader = new FileReader();
        reader.readAsText(tempFile);
    
        reader.onload = () => {
            const csvData = reader.result;
    
            const isCorrectDelimited = csvData.split('\n')[0].split(';').length > 0;
    
            if (!isCorrectDelimited)
                return Swal.fire('Archivo errado', 'Señor usuario el archivo debe tener el formato \'CSV UTF-8 delimitado por comas.\'', 'error');
            
            reduxUploadOrdersToConfirm({
                file: tempFile,
                guideOrExternalId: guideOrExternalId
            });
    
            resetUpload();
            setFileName(null);
            setFileError(null);
            setShowUpload(false);
        };
    };

    const requestUploadOrders = () =>{
        const token = window.localStorage.getItem('token');
        const requestData = {
            guideOrExternalId: guideOrExternalId,
            invalidItems: ordersUploaded.invalidItems
        };
        fetch(`${process.env.REACT_APP_REPORT_URL}/api/v1/ordersLocked/downloadExcelErrors`, {
            method: 'POST',
            body: JSON.stringify(requestData),
            headers: new Headers({
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            }),
        })
        .then((response) => response.blob())
        .then((blob) => {
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = DateTime.now().setZone(new IANAZone('America/Bogota')).toFormat('yyyy-MM-dd') + '-error-orders-locked.csv';
            document.body.appendChild(a);
            a.click();
            a.remove();
        });
    }

    const handleUploadOrdersToConfirm = () => {
        setFileName(null);
        resetUpload();
        setFileError(null);
        setShowUpload(true);
    }

    const renderTooltip = (title) => <Tooltip id='button-tooltip'>{title}</Tooltip>;

    return (
        <>
            <Show when='feature:upload-confirm-orders'>
                <OverlayTrigger
                    placement='top'
                    delay={{ show: 50, hide: 50 }}
                    overlay={renderTooltip('Bloquear ordenes')}>
                    <button
                        className='btn btn-primary btn-circle'
                        type='button'
                        onClick={handleUploadOrdersToConfirm}>
                        <i className='fas fa-upload fa-sm'></i>
                    </button>
                </OverlayTrigger>
            </Show>
            <div>
                <Modal
                    show={showUpload}
                    onHide={handleCloseUpload}
                    onEscapeKeyDown={handleCloseUpload}
                    backdrop='static'
                    keyboard={true}>
                    <form onSubmit={handleSubmitUpload(onSubmitUpload)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Bloquear Ordenes</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                    <div className="form-group col-md-12">
                                    <div className="custom-file">
                                        <input
                                            type='file'
                                            id='file'
                                            name='file'
                                            {...registerUpload('file', { required: true })}
                                            className={`custom-file-input ${errorsUpload.file && 'is-invalid'}`}
                                        />{' '}
                                        <label
                                            className='custom-file-label'
                                            htmlFor='validatedCustomFile'>
                                            {fileError ? fileError : fileName ? fileName : 'Seleccionar archivo...'}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant='secondary'
                                onClick={handleCloseUpload}>
                                Cancelar
                            </Button>
                            <Button
                                variant='primary'
                                type='submit'>
                                Guardar
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </div>
        </>
    )
}
const mapStateToProps = (state) => ({
    successUploadOrdersToConfirm: state.ordersLocked.successUploadOrdersToConfirm,
    ordersUploaded: state.ordersLocked.ordersUploaded,
})

const mapDispatchToProps = (dispatch) => {
    return {
        reduxUploadOrdersToConfirm: (payload) => {
            return dispatch({
                type: 'FETCH_UPLOAD_ORDERS_TO_CONFIRM',
                value: payload
            })
        }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UploadOrdersLocked)