import {call, put, takeLatest} from "redux-saga/effects";
import request from "../../utils/request";

function* refreshToken(data) {
  try {
    yield put({
      type: "REFRESH_TOKEN_REQUESTING",
    });

    const tokenRefresh = {token: data.payload};
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/auth/refresh`;
    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(tokenRefresh),
    };
    const response = yield call(request, requestURL, headers);
    const { payload } = response;

    if (payload.refreshToken.length > 0) {
      const newRefreshToken = payload.refreshToken;
      localStorage.removeItem("refreshToken");
      localStorage.setItem("refreshToken", newRefreshToken);
      
      yield put({
          type: 'REFRESH_TOKEN_SUCCESS',
      });
    }
  } catch (error) {
    const errorMessage = typeof error === "string" ? error : error.message;

    if (
      (errorMessage && errorMessage.includes("Internal server error")) ||
      (error.response && (error.response.status === 422 || error.response.status === 500))
    ) {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("refreshToken");
      window.location.reload();
    } else {
      yield put({
        type: "SHOW_ALERT",
        value: {
          type: "danger",
          title: "Failed Refresh",
          message: "Failed Refresh Token",
        },
      });
      yield put({
        type: "REFRESH_TOKEN_ERROR",
      });
  }
 }
}

function* logOut(data) {
  try {
    yield put({
      type: "LOG_OUT_REQUESTING",
    });

    const tokenRefresh = {token: data.payload};
    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/auth/logOut`;
    const headers = {
      method: "POST",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
      body: JSON.stringify(tokenRefresh),
    }; 
    const response = yield call(request, requestURL, headers);
    const { payload } = response;
    
    yield put({
      type: 'LOG_OUT_SUCCESS',
    });
  } catch (error) {
     yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed LOGOUT",
        message: "Failed LOGOUT",
      },
    });
    yield put({
      type: "LOG_OUT_ERROR",
    });
  }
}

function* resetPassword(payload) {
  try {
    yield put({
      type: "SHOW_LOADING",
    });

    yield put({
      type: "RESET_PASSWORD_REQUESTING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/auth/resetPassword/${payload.value.idUser}`;

    const headers = {
      method: "PATCH",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };

    yield call(request, requestURL, headers);

    yield put({
      type: "RESET_PASSWORD_SUCCESS",
    });
  } catch (error) {
    console.log(error);
    
    yield put({
      type: "RESET_PASSWORD_ERROR",
    });
  } finally {
    yield put({
      type: "HIDE_LOADING",
    });
  }
}

function* resetMassivePassword() {
  try {
    yield put({
      type: "MASSIVE_RESET_PASSWORD_REQUESTING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/auth/resetMassivePasswords`;

    const headers = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    };

    yield call(request, requestURL, headers);

    yield put({
      type: "MASSIVE_RESET_PASSWORD_SUCCESS",
    });
  } catch (error) {
    yield put({
      type: "MASSIVE_RESET_PASSWORD_ERROR",
    });
  }
}

export function* watchAuth() {
  yield takeLatest("REFRESH_TOKEN", refreshToken);
  yield takeLatest("RESET_PASSWORD_REQUEST", resetPassword);
  yield takeLatest("MASSIVE_RESET_PASSWORD_REQUEST", resetMassivePassword);
    yield takeLatest("LOG_OUT", logOut);
}