const initialState = {
    requestingFetchInvestData: false,
    successfulFetchInvestData: false,
    errorFetchInvestData: false,
    investData: [],
    total: 0,
    totalPages: 0,
  };
  
  const investDataReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'FETCH_INVEST_DATA_REQUESTING':
        return {
          ...state,
          requestingFetchInvestData: true,
          successfulFetchInvestData: false,
          errorFetchInvestData: false,
        };
      case 'FETCH_INVEST_DATA_SUCCESS':
        return {
          ...state,
          requestingFetchInvestData: false,
          successfulFetchInvestData: true,
          errorFetchInvestData: false,
          investData: action.payload.items,
          total: action.payload.total,
          totalPages: action.payload.totalPages,
        };
      case 'FETCH_INVEST_DATA_ERROR':
        return {
          ...state,
          requestingFetchInvestData: false,
          successfulFetchInvestData: false,
          errorFetchInvestData: true,
        };
      case 'RESET':
        return initialState;
      default:
        return state;
    }
  };
  
  export default investDataReducer;
  