import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Button, Modal, Spinner } from "react-bootstrap";
import Select from 'react-select'
import Swal from "sweetalert2";

import Sidebar from "../../components/Navigation/Sidebar";
import Topbar from "../../components/Navigation/Topbar";
import Header from "../../components/Page/header";
import Grid from "../../components/Grid";

import { buttonIcon, buttonTypes } from "../../utils/buttonsUtil";
import { isNullOrUndefined } from "../../utils/functionsUtils";

import { Show } from "../../hooks/Show";

const User = ({
  reduxGetRoles,
  roles,
  users,
  reduxGetUsers,
  reduxPostUser,
  user,
  reduxGetUser,
  rowEdited,
  reduxDeleteUser,
  rowDeleted,
  reduxPatchUser,
  rowUpdated,
  reduxResetUserForm,
  reduxGetHeadquarters,
  headquarters,
  reduxGetCountries,
  countries,
  reduxGetCompanies,
  companies,
  reduxGetAllUserTypes,
  allUserTypes,
  reduxGetHubs,
  hubs,
  reduxResetPassword,
  reduxResetMassivePasswords,
  reduxFlushPasswordsResetRequests,
  requestingResetPassword,
  requestingMassiveResetPassword,
  successfulResetPassword,
  errorsResetPassword,
  successfulMassiveResetPassword,
}) => {
  const {
    control: upsertModalControl,
    handleSubmit: upsertModalSubmit,
    watch: upsertModalWatch,
    formState: { errors: upsertModalErrors },
    reset: upsertModalReset,
    setValue: upsertModalSetValue,
  } = useForm({
    defaultValues: {
      firstName: '',
      secondName: '',
      lastName: '',
      secondLastName: '',
      documentNumber: '',
      email: '',
      phone: '',
      password: '',
      idRole: null,
      userName: '',
      alias: '',
      idHeadquarters: null,
      idHub: null,
      idCompany: null,
      idCountry: null,
      allowAllCountries: false,
      idType: null,
    }
  });

  const userData = JSON.parse(window.localStorage.getItem('userData'));

  let idCountry = upsertModalWatch("idCountry", '');
  const hubWatcher = upsertModalWatch("idHub", null);

  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [show, setShow] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedRol, setSelectedRol] = useState("");

  useEffect(() => {
    reduxGetUsers({
      page: 1,
      offset: offset,
      search: "",
    });
    reduxGetHeadquarters({
      page: 1,
      search: "",
      offset: 1000,
    });
    reduxGetRoles({
      page: 1,
      search: "",
      offset: 1000,
    });
    reduxGetCountries({
      page: 1,
      offset: 1000,
      search: "",
      isActive: true,
    });
    reduxGetCompanies({
      page: 1,
      search: '',
      offset: 1000,
      isActive: true,
      search: 'companyType|DSP'
    });
    reduxGetAllUserTypes({
      page: 1,
      offset: 1000,
      search: "",
    });
    reduxGetHubs();
  }, []);

  useEffect(() => {
    if (rowEdited) {
      upsertModalSetValue("firstName", rowEdited.firstName);
      upsertModalSetValue("secondName", rowEdited.secondName);
      upsertModalSetValue("lastName", rowEdited.lastName);
      upsertModalSetValue("secondLastName", rowEdited.secondLastName);
      upsertModalSetValue("documentNumber", rowEdited.documentNumber);
      upsertModalSetValue("email", rowEdited.email);
      upsertModalSetValue("phone", rowEdited.phone);
      upsertModalSetValue("userName", rowEdited.userName);
      upsertModalSetValue("isActive", rowEdited.isActive);
      upsertModalSetValue("alias", rowEdited.alias);
      upsertModalSetValue("remember_token", rowEdited.remember_token);
      upsertModalSetValue("idCompany", rowEdited.idCompany);
      upsertModalSetValue("idCountry", rowEdited.idCountry);
      upsertModalSetValue("allowAllCountries", rowEdited.allowAllCountries);
      upsertModalSetValue("idType", rowEdited.idType);

      if (rowEdited.idRole) {
        const role = roles.items.find((r) => r.idRole === rowEdited.idRole)
        upsertModalSetValue("idRole", { value: role.idRole, label: role.description });
      }

      if (rowEdited.idHeadquarters) {
        const headquarter = headquarters.items.find((f) => f.idHeadquarters === rowEdited.idHeadquarters)
        upsertModalSetValue("idHeadquarters", { value: headquarter.idHeadquarters, label: headquarter.nameHeadquarters });
      }

      if (rowEdited.idCountry) {
        const country = countries?.items?.find((f) => f.idCountry === rowEdited.idCountry)
        if (country)
          upsertModalSetValue("idCountry", { value: country?.idCountry, label: country?.description ?? 'Select...' });
      }

      if (rowEdited.idHub) {
        const hub = hubs?.find((f) => f.idBranch === rowEdited.idHub)
        if (hub)
          upsertModalSetValue("idHub", { value: hub?.idBranch, label: hub?.branchName, dsp: { value: hub.dspOperator?.idCompany, label: hub.dspOperator?.description } ?? 'Select...' });
      }

      if (rowEdited.idCompany) {
        const dsp = companies?.items?.find((f) => f.idCompany === rowEdited.idCompany)
        if (dsp)
          upsertModalSetValue("idCompany", { value: dsp?.idCompany, label: dsp?.description ?? 'Select...' });
      }

      if (rowEdited.idType) {
        const typeUser = allUserTypes?.items?.find((f) => f.idUserType === rowEdited.idType)
        if (typeUser)
          upsertModalSetValue("idType", { value: typeUser?.idUserType, label: typeUser?.Name ?? 'Select...' });
      }

      setShow(true);
    }
  }, [rowEdited, headquarters.items, upsertModalSetValue]);

  useEffect(() => {
    if (user || rowUpdated || rowDeleted) {
      if (
        (user && user.message === undefined) ||
        (rowUpdated && rowUpdated.message === undefined) ||
        rowDeleted
      ) {
        reduxGetUsers({
          page: currentPage,
          offset: offset,
          search: search,
        });
        upsertModalReset();
        reduxResetUserForm();
        setShow(false);
      }
    }
  }, [user, rowUpdated, rowDeleted]);

  useEffect(() => {
    if (successfulResetPassword) {
      Swal.fire({
        title: 'Contraseña reestablecida',
        icon: 'success',
      });
      reduxFlushPasswordsResetRequests();
      return;
    }

    if (errorsResetPassword) {
      Swal.fire({
        title: 'Error',
        text: 'No se pudo reestablecer la contraseña',
        icon: 'error',
      });
      reduxFlushPasswordsResetRequests();
      return;
    }

    if(successfulMassiveResetPassword) {
      Swal.fire({
        title: 'Contraseñas reestablecidas',
        text: 'El envío de correos electrónicos ha sido encolado',
        icon: 'success',
      });
      reduxFlushPasswordsResetRequests();
      return;
    }
  }, [successfulResetPassword, errorsResetPassword, successfulMassiveResetPassword]);

  const handleClose = () => {
    reduxResetUserForm();
    setShow(false);
    setIsCreate(false);
    setSelectedRol("");
  };

  const handleShow = () => {
    reduxResetUserForm();
    upsertModalReset();
    setShow(true);
    setIsCreate(true);
  };

  const handleOpen = async (row) => {
    reduxGetUser({
      id: row.idUser,
    });
    upsertModalReset();
    setIsCreate(false);
  };

  const handleActive = (row) => {
    if (!row.isActive) {
      reduxDeleteUser(row);
    }
  };

  const handleDelete = (row) => {
    reduxDeleteUser(row);
  };

  const isProvidersManagerRole = () => {
    const PROVIDERS_MANAGER = "PROVIDERS MANAGER";

    if (isNullOrUndefined(rowEdited)) return false;

    return (
      (rowEdited.role.description === PROVIDERS_MANAGER && !selectedRol) ||
      selectedRol === PROVIDERS_MANAGER
    );
  };

  const handleChangePassword = (rowData) => {
    Swal.fire({
      title: 'Reestablecer contraseñas',
      text: `¿Está seguro que desea reestablecer la contraseña de ${rowData.email}?`,
      icon: 'warning',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        reduxResetPassword({ idUser: rowData.idUser });
      }
    });
  }

  const handleResetMassivePasswords = () => {
    Swal.fire({
      title: 'Reestablecer contraseñas',
      text: '¿Está seguro que desea reestablecer las contraseñas de todos los usuarios?',
      icon: 'warning',
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        reduxResetMassivePasswords();
      }
    });
  }  

  const onSubmit = (data) => {
    if (rowEdited) {
      reduxPatchUser({
        ...data,
        id: rowEdited.idUser,
        idRole: data.idRole?.value,
        idHeadquarters: data.idHeadquarters?.value,
        idCompany: data.idCompany?.value ?? null,
        idCountry: data.idCountry?.value ?? null,
        idHub: data.idHub?.value || null,
        idType: data.idType?.value || null,
      });
    } else {
      reduxPostUser({
        ...data,
        idRole: data.idRole?.value,
        idHeadquarters: data.idHeadquarters?.value,
        idCompany: data.idCompany?.value,
        idCountry: data.idCountry?.value,
        idHub: data.idHub?.value || null,
        idType: data.idType?.value || null,
      });
    }
  };

  const actionButtons = [
    { permission: 'feature:create-user', onClick: handleShow, buttonType: buttonTypes.Primary, buttonIcon: buttonIcon.Add }
  ];

  const columns = [
    {
      title: "#",
      render: (rowData) => {
        return <span>{rowData.idUser}</span>;
      },
      field: 'idUser',
      searchable: true
    },
    {
      title: "Primer Nombre",
      render: (rowData) => {
        return <span>{rowData.firstName}</span>;
      },
      field: 'firstName',
      searchable: true
    },
    {
      title: "Segundo Nombre",
      render: (rowData) => {
        return <span>{rowData.secondName}</span>;
      },
      field: 'secondName',
      searchable: true
    },
    {
      title: "Primer Apellido",
      render: (rowData) => {
        return <span>{rowData.lastName}</span>;
      },
      field: 'lastName',
      searchable: true
    },
    {
      title: "Segundo Apellido",
      render: (rowData) => {
        return <span>{rowData.secondLastName}</span>;
      },
      field: 'secondLastName',
      searchable: true
    },
    {
      title: "Documento",
      render: (rowData) => {
        return <span>{rowData.documentNumber}</span>;
      },
      field: 'documentNumber',
      searchable: true
    },
    {
      title: "Alias",
      render: (rowData) => {
        return <span>{rowData.alias}</span>;
      },
      field: 'alias',
      searchable: true
    },
    {
      title: "Email",
      render: (rowData) => {
        return <span>{rowData.email}</span>;
      },
      field: 'email',
      searchable: true
    },
    {
      title: "Teléfono",
      render: (rowData) => {
        return <span>{rowData.phone}</span>;
      },
      field: 'phone',
      searchable: true
    },
    {
      title: "Rol",
      render: (rowData) => {
        return <span>{rowData.role.name}</span>;
      },
      field: 'role.name',
      searchable: true
    },
    {
      title: "Sede",
      render: (rowData) => {
        return (
          <span>
            {rowData.headquarters ? rowData.headquarters.nameHeadquarters : ""}
          </span>
        );
      },
      field: 'headquarters.nameHeadquarters',
      searchable: true
    },
    {
      title: "Opciones",
      render: (rowData) => {
        return (
          <>
            {rowData.isActive ? (
              <>
                <Show when="feature:edit-user">
                  <button
                    title="Editar"
                    className="btn btn-primary btn-sm  btn-circle mr-2"
                    type="button"
                    onClick={(e) => handleOpen(rowData)}
                  >
                    <i className="fas fa-edit fa-xs"></i>
                  </button>
                </Show>
                <Show when="feature:disabled-user">
                  <button
                    title="Desactivar"
                    className="btn btn-danger btn-sm btn-circle mr-2"
                    type="button"
                    onClick={(e) => handleDelete(rowData)}
                  >
                    <i className="fas fa-times-circle fa-xs"></i>
                  </button>
                </Show>
                {
                  ( userData.idRole === 1 || userData.idRole === -1 ) &&
                  ( rowData.idType !== 6 && rowData.idRole !== -1 ) &&
                  (
                    <Button
                      onClick={() => { handleChangePassword(rowData) }}
                      variant="warning"
                      type='button'
                      size="sm"
                      className="btn-circle"
                      disabled={requestingResetPassword}
                    >
                      {
                        requestingResetPassword ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          <i className="fas fa-burn fa-xs"></i>
                        )
                      }
                    </Button>
                  )
                }
              </>
            ) : (
              <Show when="feature:enable-user">
                <button
                  title="Activar"
                  className="btn btn-primary btn-sm  btn-circle mr-2"
                  type="button"
                  onClick={(e) => handleActive(rowData)}
                >
                  <i className="fas fa-check-circle fa-xs"></i>
                </button>
              </Show>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <Header title={"Usuarios"} subtitle={"Módulo de Administración de usuarios"} actionButtons={actionButtons} />
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex justify-content-between align-items-center">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Listado de Usuarios
                  </h6>
                  {
                    (userData.idRole === 1 || userData.idRole === -1) && (
                      <Button
                        onClick={() => { handleResetMassivePasswords() }}
                        variant='secondary'
                        type='button'
                        size="sm"
                        disabled={requestingMassiveResetPassword}
                      >
                        {
                          requestingMassiveResetPassword ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />
                          ) : (
                            <>
                              Reestablecer Contraseñas
                            </>
                          )
                        }
                      </Button>
                    )
                  }
                </div>
                <div className="card-body">
                  <Grid
                    cols={columns}
                    data={
                      users && Object.keys(users).length > 0 ? users.items : []
                    }
                    page={
                      users && Object.keys(users).length > 0
                        ? Number(users.page)
                        : currentPage
                    }
                    pages={
                      users && Object.keys(users).length > 0
                        ? Number(users.totalPages)
                        : 0
                    }
                    total={
                      users && users.hasOwnProperty("total") ? users.total : 0
                    }
                    offset={offset}
                    onChangePage={(page) => {
                      setCurrentPage(page);
                      reduxGetUsers({
                        page: page,
                        offset: offset,
                        search: search,
                      });
                    }}
                    onChangeRange={(value) => {
                      setOffset(value);
                      reduxGetUsers({
                        page: 1,
                        offset: value,
                        search: search,
                      });
                    }}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                      setSearch(value);
                      reduxGetUsers({
                        page: 1,
                        offset: offset,
                        search: value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          size="xl"
          show={show}
          onHide={handleClose}
          onEscapeKeyDown={handleClose}
          backdrop="static"
          keyboard={true}
        >
          <form onSubmit={upsertModalSubmit(onSubmit)}>
            <Modal.Header closeButton>
              <Modal.Title>
                {rowEdited ? "Editar" : "Nuevo"} Usuario
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col s12 m6">
                  <div className="form-group">
                    <label htmlFor="firstName" className="form-label">
                      Primer Nombre
                    </label>
                    <Controller
                      name="firstName"
                      control={upsertModalControl}
                      rules={{
                        required: 'El primer nombre es requerido'
                      }}
                      render={({ field }) => (
                        <>
                          <input
                            {...field}
                            id="firstName"
                            type="text"
                            className={`form-control form-control-user ${upsertModalErrors.firstName && "is-invalid"}`}
                          />
                          {
                            upsertModalErrors.firstName && (
                              <span className="error-message">
                                {upsertModalErrors.firstName.message}
                              </span>
                            )
                          }
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className="col s12 m6">
                  <div className="form-group">
                    <label htmlFor="secondName" className="form-label">
                      Segundo Nombre
                    </label>
                    <Controller
                      name="secondName"
                      control={upsertModalControl}
                      render={({ field }) => (
                        <>
                          <input
                            {...field}
                            id="secondName"
                            type="text"
                            className={`form-control form-control-user`}
                          />
                        </>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col s12 m6">
                  <div className="form-group">
                    <label htmlFor="lastName" className="form-label">
                      Primer Apellido
                    </label>
                    <Controller
                      name="lastName"
                      control={upsertModalControl}
                      rules={{
                        required: 'El primer apellido es requerido'
                      }}
                      render={({ field }) => (
                        <>
                          <input
                            {...field}
                            id="lastName"
                            type="text"
                            className={`form-control form-control-user ${upsertModalErrors.lastName && "is-invalid"}`}
                          />
                          {upsertModalErrors.lastName && (
                            <span className="error-message">
                              {upsertModalErrors.lastName.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className="col s12 m6">
                  <div className="form-group">
                    <label htmlFor="secondLastName" className="form-label">
                      Segundo Apellido
                    </label>
                    <Controller
                      name="secondLastName"
                      control={upsertModalControl}
                      render={({ field }) => (
                        <>
                          <input
                            {...field}
                            id="secondLastName"
                            type="text"
                            className={`form-control form-control-user`}
                          />
                        </>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col s12 m6">
                  <div className="form-group">
                    <label htmlFor="documentNumber" className="form-label">
                      Documento
                    </label>
                    <Controller
                      name="documentNumber"
                      control={upsertModalControl}
                      rules={{
                        required: 'El documento es requerido'
                      }}
                      render={({ field }) => (
                        <>
                          <input
                            {...field}
                            id="documentNumber"
                            type="text"
                            className={`form-control form-control-user ${upsertModalErrors.documentNumber && "is-invalid"}`}
                          />
                          {upsertModalErrors.documentNumber && (
                            <span className="error-message">
                              {upsertModalErrors.documentNumber.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className="col s12 m6">
                  <div className="form-group">
                    <label htmlFor="alias" className="form-label">
                      Alias
                    </label>
                    <Controller
                      name="alias"
                      control={upsertModalControl}
                      rules={{
                        maxLength: {
                          value: 30,
                          message: 'El alias no debe tener más de 30 caracteres',
                        },
                      }}
                      render={({ field }) => (
                        <>
                          <input
                            {...field}
                            id="alias"
                            type="text"
                            className={`form-control form-control-user ${upsertModalErrors.alias && "is-invalid"
                              }`}
                          />
                          {upsertModalErrors.alias && (
                            <span className="error-message">
                              {upsertModalErrors.alias.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col s12 m6">
                  <div className="form-group">
                    <label htmlFor="phone" className="form-label">
                      Teléfono
                    </label>
                    <Controller
                      name="phone"
                      control={upsertModalControl}
                      render={({ field }) => (
                        <>
                          <input
                            {...field}
                            id="phone"
                            type="text"
                            className={`form-control form-control-user`}
                          />
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className="col s12 m6">
                  <div className="form-group">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <Controller
                      name="email"
                      control={upsertModalControl}
                      rules={{
                        required: 'El correo electrónico es requerido'
                      }}
                      render={({ field }) => (
                        <>
                          <input
                            {...field}
                            id="email"
                            type="email"
                            className={`form-control form-control-user ${upsertModalErrors.email && "is-invalid"}`}
                          />
                          {upsertModalErrors.email && (
                            <span className="error-message">
                              {upsertModalErrors.email.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col s12 m6">
                  <div className="form-group">
                    <label htmlFor="userName" className="form-label">
                      Nombre de usuario
                    </label>
                    <Controller
                      name="userName"
                      control={upsertModalControl}
                      rules={{
                        required: {
                          value: isCreate,
                          message: 'Nombre de usuario es requerido'
                        }
                      }}
                      render={({ field }) => (
                        <>
                          <input
                            {...field}
                            id="userName"
                            type="userName"
                            className={`form-control form-control-user ${upsertModalErrors.userName && "is-invalid"}`}
                          />
                          {
                            upsertModalErrors.userName && (
                              <span className="error-message">
                                {upsertModalErrors.userName.message}
                              </span>
                            )
                          }
                        </>
                      )}
                    />
                  </div>
                </div>
                {!rowEdited && (
                  <div className="col s12 m6">
                    <div className="form-group">
                      <label htmlFor="password" className="form-label">
                        Contraseña
                      </label>
                      <Controller
                        name="password"
                        control={upsertModalControl}
                        rules={{
                          required: {
                            value: isCreate,
                            message: 'La contraseña es requerida'
                          }
                        }}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              id="password"
                              type="password"
                              className={`form-control form-control-user ${upsertModalErrors.password && "is-invalid"}`}
                            />
                            {!rowEdited && upsertModalErrors.password && (
                              <span className="error-message">
                                {upsertModalErrors.password.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col s12 m6">
                  <div className="form-group">
                    <label htmlFor="idRole" className="form-label">
                      Rol
                    </label>
                    <Controller
                      name="idRole"
                      control={upsertModalControl}
                      rules={{
                        required: 'El rol es requerido'
                      }}
                      render={({ field }) => (
                        <>
                          <Select
                            {...field}
                            id='idRole'
                            isClearable
                            onChange={(selectedOption) => {
                              field.onChange(selectedOption);
                              setSelectedRol(!isNullOrUndefined(selectedOption) ? selectedOption.label : '');
                            }}
                            options=
                            {
                              roles && Object.keys(roles).length > 0 &&
                              roles.items
                                .map((ele) => ({
                                  value: ele.idRole,
                                  label: ele.description,
                                }))
                            }
                          />
                          {
                            upsertModalErrors.idRole && (
                              <span className="error-message">
                                {upsertModalErrors.idRole.message}
                              </span>
                            )
                          }
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className="col s12 m6">
                  <div className="form-group">
                    <label htmlFor="idHeadquarters" className="form-label">
                      Sede
                    </label>
                    <Controller
                      name="idHeadquarters"
                      control={upsertModalControl}
                      rules={{
                        required: 'El primer nombre es requerido'
                      }}
                      render={({ field }) => (
                        <>
                          <Select
                            {...field}
                            id='idHeadquarters'
                            isClearable
                            options=
                            {
                              headquarters && Object.keys(headquarters).length > 0 &&
                              headquarters.items
                                .map((ele) => ({
                                  value: ele.idHeadquarters,
                                  label: ele.nameHeadquarters,
                                }))
                            }
                          />
                        </>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col s12 m6">
                  <div className="form-group">
                    <label htmlFor="idCountry" className="form-label">
                      Pais
                    </label>
                    <Controller
                      name="idCountry"
                      control={upsertModalControl}
                      rules={{ required: 'El país es requerido' }}
                      render={({ field }) => (
                        <>
                          <Select
                            {...field}
                            id='idCountry'
                            isClearable
                            onChange={(e) => {
                              field.onChange(e);
                              upsertModalSetValue('idHub', null);
                              upsertModalSetValue('idCompany', null);
                            }}
                            options=
                            {
                              countries && Object.keys(countries).length > 0
                                ? countries.items
                                  .map((ele) => ({
                                    value: ele.idCountry,
                                    label: ele.description,
                                  }))
                                : []
                            }
                          />
                          {upsertModalErrors.idCountry && (
                            <span className="error-message">
                              {upsertModalErrors.idCountry.message}
                            </span>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className="col s12 m6">
                  <div className="form-group">
                    <label htmlFor="idHub" className="form-label">
                      HUB
                    </label>
                    <Controller
                      name="idHub"
                      control={upsertModalControl}
                      render={({ field }) => (
                        <>
                          <Select
                            {...field}
                            id='idHub'
                            isClearable
                            onChange={(e) => {
                              field.onChange(e);
                              upsertModalSetValue('idCompany', e?.dsp || null);
                            }}
                            options=
                            {
                              hubs && Object.keys(hubs).length > 0
                                ? hubs
                                  .filter((f) => f.idCountry == idCountry?.value)
                                  .map((ele) => ({
                                    value: ele.idBranch,
                                    label: ele.branchName,
                                    dsp: ele.dspOperator && { value: ele.dspOperator.idCompany, label: ele.dspOperator.description },
                                  }))
                                : []
                            }
                          />
                        </>
                      )}
                    />
                  </div>
                </div>
                <div className="col s12 m6">
                  <div className="form-group">
                    <label htmlFor="idCompany" className="form-label">
                      DSP
                    </label>
                    <Controller
                      name="idCompany"
                      control={upsertModalControl}
                      render={({ field }) => (
                        <>
                          <Select
                            {...field}
                            id='idCompany'
                            isDisabled={isNullOrUndefined(idCountry) || hubWatcher?.dsp}
                            isClearable
                            options=
                            {
                              companies && Object.keys(companies).length > 0
                                ? companies.items
                                  .filter((f) => f.country == idCountry?.value)
                                  .map((ele) => ({
                                    value: ele.idCompany,
                                    label: ele.description,
                                  }))
                                : []
                            }
                          />
                          {
                            upsertModalErrors.idCompany && (
                              <span className="error-message">
                                {upsertModalErrors.idCompany.message}
                              </span>
                            )
                          }
                        </>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6 s12 m6">
                  <div className="form-group">
                    <label htmlFor="idType" className="form-label">
                      Tipo de usuario
                    </label>
                    <Controller
                      name="idType"
                      control={upsertModalControl}
                      render={({ field }) => (
                        <>
                          <Select
                            {...field}
                            id='idType'
                            isClearable
                            options=
                            {
                              allUserTypes && Object.keys(allUserTypes).length > 0 &&
                              allUserTypes.items
                                .map((ele) => ({
                                  value: ele.idUserType,
                                  label: ele.Name,
                                }))
                            }
                          />
                          {
                            upsertModalErrors.idType && (
                              <span className="error-message">
                                {upsertModalErrors.idType.message}
                              </span>
                            )
                          }
                        </>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-3" style={{ top: '5px' }}>
                  <Controller
                    name="allowAllCountries"
                    control={upsertModalControl}
                    render={({ field }) => (
                      <>
                        <label htmlFor="allowAllCountries">
                          <input
                            id="allowAllCountries"
                            type="checkbox"
                            {...field}
                            checked={field.value}
                            style={{ marginRight: '10px', marginLeft: '10px' }}
                          />
                          Acceder a todos los países
                        </label>
                      </>
                    )}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit">
                Guardar
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.userState.users,
    user: state.userState.user,
    rowEdited: state.userState.rowEdited,
    rowDeleted: state.userState.rowDeleted,
    rowUpdated: state.userState.rowUpdated,
    roles: state.roleState.roles,
    headquarters: state.headquartersState.headquarters,
    countries: state.countryState.countries,
    companies: state.companyState.companies,
    hubs: state.branchState.allBranches,
    allUserTypes: state.userTypeState.allUserTypes,
    requestingMassiveResetPassword: state.auth.requestingMassiveResetPassword,
    requestingResetPassword: state.auth.requestingResetPassword,
    successfulResetPassword: state.auth.successfulResetPassword,
    errorsResetPassword: state.auth.errorsResetPassword,
    successfulMassiveResetPassword: state.auth.successfulMassiveResetPassword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetRoles: (payload) =>
      dispatch({
        type: "FETCH_ROLES_REQUEST",
        value: payload,
      }),
    reduxGetHeadquarters: (payload) =>
      dispatch({
        type: "FETCH_HEADQUARTERS_REQUEST",
        value: payload,
      }),
    reduxGetUsers: (payload) =>
      dispatch({
        type: "FETCH_USERS_REQUEST",
        value: payload,
      }),
    reduxPostUser: (payload) =>
      dispatch({
        type: "CREATE_USER_REQUEST",
        value: payload,
      }),
    reduxGetUser: (payload) =>
      dispatch({
        type: "READ_USER_REQUEST",
        value: payload,
      }),
    reduxDeleteUser: (payload) =>
      dispatch({
        type: "DELETE_USER_REQUEST",
        value: payload,
      }),
    reduxPatchUser: (payload) =>
      dispatch({
        type: "UPDATE_USER_REQUEST",
        value: payload,
      }),
    reduxResetUserForm: () =>
      dispatch({
        type: "RESET_USER_FORM",
      }),
    reduxGetCountries: (payload) =>
      dispatch({
        type: "FETCH_COUNTRIES_REQUEST",
        value: payload,
      }),
    reduxGetCompanies: (payload) =>
      dispatch({
        type: 'FETCH_COMPANIES_REQUEST',
        value: payload,
      }),
    reduxGetHubs: (payload) =>
      dispatch({
        type: 'FETCH_ALL_BRANCHES_REQUEST',
        value: payload,
      }),
    reduxGetAllUserTypes: (payload) =>
      dispatch({
        type: 'GET_ALL_USER_TYPES',
        value: payload,
      }),
    reduxResetPassword: (payload) =>
      dispatch({
        type: 'RESET_PASSWORD_REQUEST',
        value: payload,
      }),
    reduxResetMassivePasswords: () =>
      dispatch({
        type: 'MASSIVE_RESET_PASSWORD_REQUEST',
      }),
    reduxFlushPasswordsResetRequests: () =>
      dispatch({
        type: 'FLUSH_PASSWORD_RESET_REQUESTS',
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
