import React from 'react';
import { Modal } from "react-bootstrap";
import { useSelector } from 'react-redux'

const ModalErrorReadGuides = ({
    showErrorsModal,
    handleCloseErrorsModal,
}) => {

    const bulkGuideRead = useSelector(state => state.addressState.bulkGuideRead);

    return (
        <>
            <Modal
                show={showErrorsModal}
                onHide={handleCloseErrorsModal}
                onEscapeKeyDown={handleCloseErrorsModal}
                backdrop="static"
                keyboard={false}
                size="lg"
                dialogClassName="modal-error-size"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Guías no encontradas</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="shadow mb-3">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="table-responsive" style={{ maxHeight: '65VH', overflowY: 'auto' }}>
                                    <table className="table table-bordered dataTable table-sm">
                                        <thead>
                                            <tr>
                                                <th>Guía no registrada en el sistema</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {bulkGuideRead.notFoundAddresses.length > 0 && (
                                                bulkGuideRead.notFoundAddresses.map((errorGuide, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {errorGuide.trackingId}
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default ModalErrorReadGuides;