import {  useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import moment from "moment-timezone";
import Swal from "sweetalert2";

import { statusPayments } from "../../utils/enumsUtil";
import Grid from "../../components/Grid";
import { Show } from "../../hooks/Show";
import { isNullOrUndefined } from "../../utils/functionsUtils";
import { MdContentCopy } from "react-icons/md";

function PreOrdersTable({ order, preOrders, reduxGetAllPreOrders, successfulCreatePreOrders, reduxGetOrderByTrackingOrIdAddress, reduxResetOrder, reduxGetOrderByIdPreOrder }){
    const [validateOrder, setValidateOrder] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const { register, reset, setValue } = useForm();
    const [offset, setOffset] = useState(10);
    const [show, setShow] = useState(false);
    const [search, setSearch] = useState()
    const [urlWhatsapp, setUrlWhatsapp] = useState()
    const [urlCheckout, setUrlCheckout] = useState()

    useEffect(() => {
        reduxGetAllPreOrders({
            page: currentPage,
            offset: offset,
            search: search
        })
    }, []);

    useEffect(() => {
        if(successfulCreatePreOrders){
            reduxGetAllPreOrders({
                page: currentPage,
                offset: offset,
                search: search
            })
        }
    }, [successfulCreatePreOrders]);

    useEffect(() => {
        if(!validateOrder) return;
        if(order?.preOrder?.length == 0){
            Swal.fire({
                title: 'Error',
                text: 'Ocurrio un error al consultar el crédito',
                icon: 'error',
                confirmButtonText: 'OK'
            });
            handleClose();
            return;
        }
        if(order){
            const url = `${process.env.REACT_APP_CHECKOUT}/${order.hashId ?? ''}`;
            setValue('phone', order?.preOrder[0]?.reference1);
            setValue('text', `!Hola¡\n Somos Kiki Latam, te enviamos el link para que realices tu proceso de solicitud de crédito. Agradecemos finalices tu compra. \n ${url}`);
            configWhatsappUrl('checkout');
        }
    }, [order]);

    const columns = [
        {
            title: '#',
            render: (rowData) => {
              return <span>{rowData.idPreOrder}</span>;
            },
            field: 'id_pre_order',
            searchable: true,
        },
        {
            title: 'ID externo',
            render: (rowData) => {
              return <span>{rowData?.order?.externalId}</span>;
            },
            field: 'order.externalId',
            searchable: true,
        },
        {
            title: 'IdAddress',
            render: (rowData) => {
              return <span>{rowData?.order?.idAddress}</span>;
            },
            field: 'order.idAddress',
            searchable: true,
        },
        {
            title: 'Estado',
            render: (rowData) => {
              return <span>{statusPayments[rowData?.order?.status] ?? 'ERROR'}</span>;
            },
            field: 'order.externalId',
            searchable: true,
        },
        {
            title: 'Metodo Pago',
            render: (rowData) => {
              return <span>{rowData?.order?.paymentMethod?.type} {rowData?.order?.paymentMethod?.method}</span>;
            },
            field: 'order.externalId',
            searchable: false,
        },
        {
            title: 'Ciudad',
            render: (rowData) => {
              return <span>{rowData.city ? rowData.city.description : ''}</span>;
            },
            field: 'city.description',
            searchable: true,
        },
        {
            title: 'Departamento',
            render: (rowData) => {
              return (
                <span>
                  {rowData.city && rowData.city.department ? rowData.city.department.description : ''}
                </span>
              );
            },
            field: 'department.description',
            searchable: true,
        },
        {
            title: 'Dirección Destinatario',
            render: (rowData) => {
                return <span>{rowData.address}</span>;
            },
            field: 'PO.address',
            searchable: true,
        },
        {
            title: 'Valor a Recaudar',
            render: (rowData) => {
                return <span>{rowData.order.ammount}</span>;
            },
            field: 'order.ammount',
            searchable: true,
        },
        {
            title: 'Compañia',
            render: (rowData) => {
                return <span>{rowData.order.company ? rowData.order.company.description : ''}</span>;
            }
        },
        {
            title: 'Fecha',
            render: (rowData) => {
                return <span>{moment(rowData.order.date).tz('America/Bogota').format('DD-MM-YYYY')}</span>;
            },
        },
        {
            title: 'Link',
            render: (rowData) => {
                return <span>{process.env.REACT_APP_CHECKOUT}/{rowData?.order?.hashId ?? ''}</span>;
            }
        },
        {
            title: 'Opciones',
            render: (rowData) => {
                return (
                    <>
                        {   rowData?.order && rowData?.order?.status === 1 &&
                            (<Show when='feature:pre-orders-create'>
                                <button
                                    title='Send Checkout Link'
                                    className='btn btn-success btn-sm  btn-circle mr-2'
                                    type='button'
                                    onClick={(e) => sendCheckoutUrlModal(rowData?.idPreOrder)}>
                                    <i className="fab fa-whatsapp fa-lg"></i>
                                </button>
                            </Show>)
                        }
                    </>
                );
            },
        }
    ];

    const configWhatsappUrl = (url) => {
        let urlCheckout = `${process.env.REACT_APP_CHECKOUT}/${order.hashId}`;
        let message = `!Hola¡\nSomos Kiki Latam, te enviamos el link para que realices tu proceso de solicitud de crédito. Agradecemos finalices tu compra. \n${urlCheckout}`;

        if(url === 'kuesky'){
            message = `!Hola¡\n Somos Kiki Latam, Este es el link para que realices tu proceso de solicitud de crédito en Kuesky. \n${order?.payment[0]?.url}`;
            urlCheckout = order?.payment[0]?.url;
        }
        setValue('text', message);
        setUrlWhatsapp(urlCheckout);
        setUrlCheckout(url);
    }

    const handleClose = () => {
        reset();
        setShow(false);
        reduxResetOrder();
        setValidateOrder(false);
        setUrlWhatsapp();
    }

    const sendCheckoutUrlModal = (idPreOrder) => {
        reduxGetOrderByIdPreOrder({idPreOrder: idPreOrder});
        setValidateOrder(true)
        setShow(true);
    }

    return <>
        <Grid
            cols={columns}
            data={preOrders && Object.keys(preOrders).length > 0 ? preOrders.items : []}
            page={preOrders && Object.keys(preOrders).length > 0 ? Number(preOrders.page) : currentPage}
            pages={preOrders && Object.keys(preOrders).length > 0 ? Number(preOrders.totalPages) : 1}
            total={preOrders && preOrders.hasOwnProperty('total') ? preOrders.total : 0}
            offset={offset}
            onChangePage={(value) => {
                setCurrentPage(value)
                reduxGetAllPreOrders({
                    page: value,
                    offset: offset,
                    search: search
                })
            }}
            onChangeRange={(value) => {
                setOffset(value)
                reduxGetAllPreOrders({
                    page: currentPage,
                    offset: value,
                    search: search
                })
            }}
            defaultValue={search}
            onChangeSearch={(value) => {
                setCurrentPage(1)
                setSearch(value)
                reduxGetAllPreOrders({
                    page: 1,
                    offset: offset,
                    search: value
                })
            }}
        />
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="md"
        >
            <Modal.Header closeButton>
                <Modal.Title>Link</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form method="GET" action="https://api.whatsapp.com/send" target="_blank">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label>Celular</label>
                                <input type="text" className="form-control" {...register('phone')} />
                                <input type="hidden" className="form-control" {...register('text')}/>
                            </div>
                        </div>
                        { order?.payment?.length > 0 && !isNullOrUndefined(order?.payment[0]?.url) && (<div className="col-md-12">
                            <div className="form-group">
                                <label>¿Que URL quieres enviar?</label>
                            </div>
                            <div className="form-group d-flex justify-content-center">
                                <button type="button" className={`btn ${urlCheckout === 'checkout' ? 'btn-success' : 'btn-outline-secondary'}`} data-bs-toggle="button" onClick={(e) => configWhatsappUrl('checkout')}>Checkout</button>
                                <button type="button" className={`btn ml-3 ${urlCheckout === 'kuesky' ? 'btn-success' : 'btn-outline-secondary'}`} data-bs-toggle="button" onClick={(e) => configWhatsappUrl('kuesky')}>Kuesky</button>
                            </div>
                            <div className="form-group d-flex justify-content-between">
                                <input type="text" className="form-control" value={urlWhatsapp} disabled/>
                                <button title="copiar" type="button" onClick={() => navigator.clipboard.writeText(urlWhatsapp)} style={{ background: 'none', border: 'none', padding: 0, marginLeft: '12px' }}>
                                    <MdContentCopy style={{ cursor: 'pointer', fontSize: '30px', color: '#afafaf' }} />
                                </button>
                            </div>
                        </div>)}
                        <div className="col-md-12 d-flex justify-content-center">
                            <button id="send_checkout" type="submit" className="btn btn-primary">Continuar</button>
                            <button type="button" className="btn btn-secondary ml-3" onClick={handleClose}>Cancelar</button>
                        </div>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    </>
}

const mapStateToProps = (state) => {
    return {
        preOrders: state.preOrderState.preOrders,
        successfulCreatePreOrders: state.preOrderState.successfulCreatePreOrders,
        order: state.orderState.order
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetAllPreOrders: (payload)=>
            dispatch({
                type: 'FETCH_PRE_ORDER_REQUEST',
                value: payload
            }),
        reduxGetOrderByTrackingOrIdAddress: (payload) =>
            dispatch({
                type: "FETCH_ORDER_BY_TRACKING_OR_ID_ADDRESS",
                value: payload,
            }),
        reduxGetOrderByIdPreOrder: (payload) =>
            dispatch({
                type: "FETCH_ORDER_BY_ID_PREORDER",
                value: payload,
            }),
        reduxResetOrder: () =>
            dispatch({
                type: "RESET_ORDER_FORM",
            })
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(PreOrdersTable)