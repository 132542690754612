import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from "react-bootstrap";
import { useSelector } from 'react-redux'
import { buttonIcon } from "../../../utils/buttonsUtil";
import ModalReadGuideTimeLine from '../../../components/Modal/BulkGuideReader/ModalReadGuideTimeLine';
import ModalErrorReadGuides from '../../../components/Modal/BulkGuideReader/ModalErrorReadGuides';
import { isNullOrUndefined } from '../../../utils/functionsUtils';

const TableListGuides = ({ }) => {

    const [showModalTimeLine, setShowTimeLine] = useState(false);
    const [showErrorsModal, setShowErrorsModal] = useState(false);
    const [selectedTimeLineIndex, setSelectedTimeLineIndex] = useState(0);
    const bulkGuideRead = useSelector(state => state.addressState.bulkGuideRead);

    const tableHeaders = [
        "#",
        "ID externo",
        "Guía",
        "Estado",
        "Guía de Retorno",
        "Operador",
        "Destinatario",
        "Dirección Destinatario",
        "Coordenadas",
        "Teléfono Destinatario",
        "Observaciones",
        "Valor a recaudar",
        "Ciudad",
        "Departamento",
        "Remitente",
        "Bodega",
        "Fecha de reprogramacion",
        "Acciones"
    ];

    const totalUploadedAddress = () => {
        if (isNullOrUndefined(bulkGuideRead)) {
            return { failedGuides: 0, successfulGuides: 0, totalGuides: 0 };
        }

        return {
            failedGuides: bulkGuideRead.notFoundAddresses.length,
            successfulGuides: bulkGuideRead.foundAddresses.length,
            totalGuides: bulkGuideRead.foundAddresses.length + bulkGuideRead.notFoundAddresses.length
        }
    }

    const resultRequestedAddress = totalUploadedAddress();

    const rescheduled = (items) => {
        let maxDate = null;
        items.forEach((item) => {
            if (item.date !== null && item.date !== '' && item.idNewState === 1) {
                const currentDate = new Date(item.date);
                if (maxDate === null || currentDate > maxDate) {
                    maxDate = currentDate;
                }
            }
        });

        if (maxDate !== null) {
            const formattedDate = formatDate(maxDate);
            return formattedDate;
        } else {
            return '';
        }
    };

    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    };

    const handleShowTimeLine = (index) => {
        setSelectedTimeLineIndex(index);
        setShowTimeLine(true);
    };

    const handleCloseTimeLine = () => {
        setShowTimeLine(false);
    }

    const handleCloseErrorsModal = () => {
        setShowErrorsModal(false)
    }

    return (
        <div>
            <div className="table-responsive" style={{ maxHeight: '70VH', overflowY: 'auto' }}>
                <table className="table table-bordered dataTable table-sm">
                    <thead>
                        <tr>
                            {tableHeaders.map((header, index) => (
                                <th key={index} style={{ textAlign: 'center', position: index === 2 ? 'sticky' : 'static', left: index === 2 ? 0 : 'auto', background: index === 2 ? '#fff' : 'inherit' }}>{header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {bulkGuideRead.foundAddresses.map((address, index) => (
                            <tr key={index}>
                                <td className="text-nowrap">{address.idAddress}</td>
                                <td className="text-nowrap">{address.externalId}</td>
                                <td className="text-nowrap" style={{ position: 'sticky', left: 0, background: '#fff' }}>{address.trackingId}</td>
                                <td className="text-nowrap">{address.newState.name}</td>
                                <td className="text-nowrap">{address.returnTrackingId}</td>
                                <td className="text-nowrap">{address.operator.description}</td>
                                <td className="text-nowrap">{`${address.firstName} ${address.lastName}`}</td>
                                <td className="text-nowrap" title={address.address}>{address.address}</td>
                                <td className="text-nowrap">{address.coordinates}</td>
                                <td className="text-nowrap">{address.reference1}</td>
                                <td className="text-nowrap">{address.reference2}</td>
                                <td className="text-nowrap">{address.collection}</td>
                                <td className="text-nowrap">{address.city ? address.city.description : ''}</td>
                                <td className="text-nowrap">{address.city && address.city.department ? address.city.department.description : ''}</td>
                                <td className="text-nowrap">{address.company ? address.company.description : ''}</td>
                                <td className="text-nowrap">{address.branch ? address.branch.branchName : ''}</td>
                                <td className="text-nowrap">{rescheduled(address.addressRecords)}</td>
                                <td className="d-flex justify-content-center align-items-center">
                                    <Button
                                        variant="success"
                                        type="button"
                                        title="Timeline"
                                        className="btn-sm rounded-pill"
                                        onClick={() => handleShowTimeLine(index)}
                                    >
                                        <i className={buttonIcon.Eye}></i>
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div>
                <ModalReadGuideTimeLine
                    showModalTimeLine={showModalTimeLine}
                    handleCloseTimeLine={handleCloseTimeLine}
                    selectedTimeLineIndex={selectedTimeLineIndex}
                />

                <ModalErrorReadGuides
                    showErrorsModal={showErrorsModal}
                    handleCloseErrorsModal={handleCloseErrorsModal}
                />

                <footer className='mt-3 d-flex align-items-center'>
                    <div>
                        <strong>
                            {resultRequestedAddress.successfulGuides}/{resultRequestedAddress.totalGuides} guías encontradas
                        </strong>
                    </div>
                    <div>
                        {bulkGuideRead.notFoundAddresses.length > 0 && (
                            <Button
                                variant="warning"
                                type="button"
                                title="Guías no encontradas"
                                className="rounded-circle btn-sm ml-3"
                                onClick={() => setShowErrorsModal(true)}
                            >
                                <i className={buttonIcon.Eye}></i>
                            </Button>
                        )}
                    </div>
                </footer>
            </div>
        </div>
    );
}

export default TableListGuides;