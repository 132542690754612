import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { Modal } from "react-bootstrap";
import { StatusEnum } from '../../../../utils/enumsUtil';
import { isNullOrUndefined } from '../../../../utils/functionsUtils';
import ImageS3 from '../../../ImageS3'
import { PermissionContext } from '../../../../hooks/PermissionContext';
import { formatDateWithCustomFormat, FormatsDate } from "../../../../utils/formatDate"
import { getStatusBadgeByCode, getStatusDataByCode } from "../../../../utils/StatusUtils";
import { buttonIcon, buttonTypes } from "../../../../utils/buttonsUtil";

const ModalReadGuideTimeLine = ({
    selectedTimeLineIndex,
    showModalTimeLine,
    handleCloseTimeLine,
    bulkGuideRead,
}) => {

    const { hasPermission } = useContext(PermissionContext);
    const [currentAddressInModal, setCurrentAddressInModal] = useState(selectedTimeLineIndex);

    const getDetailEvent = (ele) => {
        switch (ele.status.code) {
            case StatusEnum.NOENTR:
            case StatusEnum.CREADO:
            case StatusEnum.ENTRAN:
            case StatusEnum.ALISTA:
            case StatusEnum.ENESPE:
            case StatusEnum.ENTREG:
            case StatusEnum.DESPAC:
            case StatusEnum.CENRET:
            case StatusEnum.CENDIS:
            case StatusEnum.ANULAD:
            case StatusEnum.ASIGNA:
            case StatusEnum.REPROG:
            case StatusEnum.INVEST:
                return renderFullDetail(ele);
        }
    };

    useEffect(() => {
        setCurrentAddressInModal(selectedTimeLineIndex);
    }, [selectedTimeLineIndex]);

    const renderFullDetail = (ele) => {
        return (
            <>
                <h2 className="mb-1">
                    <a href="#">{getFullName(ele)}</a>{" "}
                    <span>Cambio estado a: {getStatusBadgeByCode(ele.status.code)}</span>
                </h2>
                {!isNullOrUndefined(ele.user) && !isNullOrUndefined(ele.user.firstName) &&
                    ele.user.firstName !== '' && !isNullOrUndefined(ele.idUser) && (
                        <div>
                            <span className="text-muted">Mensajero :</span>{" "}
                            {ele.user.firstName} {ele.user.lastName}
                        </div>
                    )}
                {!isNullOrUndefined(ele.novelty.description) && (
                    <div>
                        <span className="text-muted">Novedad :</span>{" "}
                        {hasPermission('feature:isClient') && ele.status.code === StatusEnum.INVEST
                            ? "En investigación"
                            : ele.novelty.description}
                    </div>
                )}
                {ele.noteDescription != null && (
                    <div>
                        <span>Nota :</span> {ele.noteDescription}
                    </div>
                )}
                {ele.txtNote != null && ele.txtNote != "" && (
                    <div>
                        <span>{"Comentario"} : </span> {ele.txtNote}
                    </div>
                )}
                {!isNullOrUndefined(ele.deliveryLocation) && (
                    <div>
                        <span>{"Ubicación de Entrega"} : </span> {ele.deliveryLocation}
                    </div>
                )}
                {!isNullOrUndefined(ele.picture) && (
                    <div className="row mt-2">
                        <div className="col-lg-12">
                            <span>Fotos evidencia: </span>
                            <div className="d-flex">
                                <div className="mt-2">
                                    <ImageS3
                                        picture={`${ele.picture}`}
                                        alt=""
                                        className="img-thumbnail"
                                    />
                                </div>
                                {!isNullOrUndefined(ele.picture_2) && (
                                    <div className="mt-2">
                                        <ImageS3
                                            picture={`${ele.picture_2}`}
                                            alt=""
                                            className="img-thumbnail"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {ele.signature != null && ele.signature != "" && (
                    <div className="row clearfix mt-2">
                        <div className="col-lg-12">
                            <span>Firma: </span>
                            <div className="map m-t-10 mt-2">
                                <ImageS3
                                    picture={`${ele.signature}`}
                                    alt=""
                                    className="img-fluid img-thumbnail m-t-30"
                                />
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    };

    const handleClickChangeAddress = (process) => {
        switch (process) {
            case "increase":
                setCurrentAddressInModal(currentAddressInModal + 1)
                break;
            case "decrease":
                setCurrentAddressInModal(currentAddressInModal - 1)
                break;
            default:
                break;
        }

    }

    const getFullName = (record) => {
        let firstName = 'N/A';
        let lastName = '';

        if (hasPermission('feature:isClient') && !isNullOrUndefined(record.user) && !isNullOrUndefined(record.user.firstName)) {

            firstName = record.user.firstName;
            lastName = record.user.lastName;
        } else if (firstName = !isNullOrUndefined(record.userSession) && !isNullOrUndefined(record.userSession.firstName)) {
            firstName = record.userSession.firstName;
            lastName = !isNullOrUndefined(record.userSession.lastName) ? record.userSession.lastName : '';
        }
        return `${firstName} ${lastName}`;
    };

    return (
        <>
            <Modal
                show={showModalTimeLine}
                onHide={handleCloseTimeLine}
                onEscapeKeyDown={handleCloseTimeLine}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Timeline</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        {bulkGuideRead.foundAddresses.length > 0 && (
                            <div className="card shadow mb-1">
                                <li className="list-group-item lh-condensed d-flex justify-content-between align-items-center">
                                    {currentAddressInModal > 0 && (
                                        <button
                                            title='Anterior guía'
                                            className={`btn ${buttonTypes.Primary} btn-circle`}
                                            type="button"
                                            onClick={() => handleClickChangeAddress("decrease")}
                                        >
                                            <i className={`${buttonIcon.Arrow_AngleLeft}`}></i>
                                        </button>
                                    )}
                                    <div className="flex-grow-1 text-center">
                                        <h6 className="my-0 font-weight-bold">
                                            Guía numero: {bulkGuideRead.foundAddresses[currentAddressInModal].trackingId}
                                        </h6>
                                    </div>
                                    {currentAddressInModal < bulkGuideRead.foundAddresses.length - 1 && (
                                        <button
                                            title='Siguiente guía'
                                            className={`btn ${buttonTypes.Primary} btn-circle`}
                                            type="button"
                                            onClick={() => handleClickChangeAddress("increase")}
                                        >
                                            <i className={`${buttonIcon.Arrow_AngleRight}`}></i>
                                        </button>
                                    )}
                                </li>
                            </div>
                        )}
                        <div className="card shadow mb-3">
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <ul className="cbp_tmtimeline mr-3">
                                        {bulkGuideRead.foundAddresses.length > 0 &&
                                            (bulkGuideRead.foundAddresses[currentAddressInModal].addressRecords.map((ele, key) => {
                                                return (
                                                    <li key={key}>
                                                        <time className="cbp_tmtime" dateTime="2017-10-22T12:13">
                                                            <span>
                                                                {formatDateWithCustomFormat(ele.date, {
                                                                    format: FormatsDate.TIME_HOURS,
                                                                })}
                                                            </span>
                                                            <span>
                                                                {formatDateWithCustomFormat(ele.date, {
                                                                    format: "DD-MM-YYYY",
                                                                })}
                                                            </span>
                                                        </time>
                                                        <div className={`cbp_tmicon bg-${getStatusDataByCode(ele.status.code).class.bg}`}>
                                                            <i className={`${getStatusDataByCode(ele.status.code).class.icon}`}></i>
                                                        </div>
                                                        <div className="cbp_tmlabel">{getDetailEvent(ele)}</div>
                                                    </li>
                                                );
                                            }))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
};

const mapStateToProps = (state) => {
    return {
        bulkGuideRead: state.addressState.bulkGuideRead,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalReadGuideTimeLine);