import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import forgotPasswordReducer from "./forgotPasswordReducer";
import countryReducer from "./countryReducer";
import departmentReducer from "./departmentReducer";
import cityReducer from "./cityReducer";
import roleReducer from "./roleReducer";
import areaReducer from "./areaReducer";
import adjustmentReducer from "./adjustmentReducer"
import zoneReducer from "./zoneReducer";
import neighborhoodReducer from "./neighborhoodReducer";
import companyReducer from "./companyReducer";
import permissionReducer from "./permissionReducer";
import userReducer from "./userReducer";
import userTypeReducer from "./userTypeReducer";
import permissionRoleReducer from "./permissionRoleReducer";
import addressReducer from "./addressReducer";
import routeReducer from "./routeReducer";
import alertReducer from "./alertReducer";
import loadingReducer from "./loadingReducer";
import permissionsReducer from "./permissionsReducer";
import authReducer from "./authReducer";
import observationReducer from "./observationReducer";
import zoneNeighborhoodReducer from "./zoneNeighborhoodReducer";
import recordReducer from "./recordReducer";
import addressesEventReducer from "./addressesEventReducer";
import headquartersReducer from "./headquartersReducer";
import routeRecordReducer from "./routeRecordReducer";
import reportReducer from "./reportReducer";
import changePasswordReducer from "./changePasswordReducer";

import lotReducer from "./lotReducer";
import productReducer from "./productReducer";
import orderReducer from "./orderReducer";
import customerReducer from "./customerReducer";
import categoryReducer from "./categoryReducer";
import orderDetailReducer from "./orderDetailReducer";
import LotProductReducer from "./lotProductReducer";
import CollectReducer from "./collectReducer";
import externalMappingReducer from "./externalMappingReducer";
import pdfTrackingReducer from "./pdfTrackingReducer";
import noteReducer from "./noteReducer";
import addressCardinalReducer from "./addressCardinalReducer";
import addressInsideTypeReducer from "./addressInsideTypeReducer";
import addressNomenclatureReducer from "./addressNomenclatureReducer";
import addressRoadTypeReducer from "./addressRoadTypeReducer";
import addressCloseConfirmationReducer from "./AddressCloseConfirmationReducer";
import StatusReducer from "./addressStatusReducer";
import financialReducer from "./financialReducer";
import auditoriaNotificationsReducer from "./auditoriaNotificationsReducer";
import branchReducer from "./branchReducer";
import fdxCartaPorteReducer from "./fdxCartaPorteReducer";
import statusesReducer from './statusesReducer'
import noveltyReducer from "./noveltyReducer";
import OrdersLockedReducer from "./OrdersLockedReducer";
import assignOperatorReducer from "./assignOperatorReducer";
import warehouseReducer from "./warehouseReducer";
import reschedulingReducer from "./reschedulingReducer";
import inventoryReducer from "./inventoryReducer";
import coverageReducer from "./coverageReducer";
import originReducer from "./originReducer";
import alertTypeReducer from "./alertTypeReducer";
import alertSourceReducer from "./alertSourceReducer";
import dspReducer from "./dspReducer";
import dspOrderAssignCourierReducer from "./dspOrderAssignCourierReducer";
import wmsReducer from "./wmsReducer";
import preOrderReducer from "./preOrderReducer";
import investDataReducer from "./securityModuleReducer";

export const rootReducer = combineReducers({
  loginState: loginReducer,
  forgotPasswordState: forgotPasswordReducer,
  countryState: countryReducer,
  departmentState: departmentReducer,
  cityState: cityReducer,
  roleState: roleReducer,
  areaState: areaReducer,
  adjustmentState: adjustmentReducer,
  zoneState: zoneReducer,
  neighborhoodState: neighborhoodReducer,
  companyState: companyReducer,
  permissionState: permissionReducer,
  userState: userReducer,
  userTypeState: userTypeReducer,
  permissionRoleState: permissionRoleReducer,
  addressState: addressReducer,
  routeState: routeReducer,
  alertState: alertReducer,
  loadingState: loadingReducer,
  permissions: permissionsReducer,
  auth: authReducer,
  observationState: observationReducer,
  zoneNeighborhoodState: zoneNeighborhoodReducer,
  recordState: recordReducer,
  noveltyState: noveltyReducer,
  addressesEventState: addressesEventReducer,
  headquartersState: headquartersReducer,
  routeRecordState: routeRecordReducer,
  reportState: reportReducer,
  changePasswordState: changePasswordReducer,
  lotState: lotReducer,
  productState: productReducer,
  orderState: orderReducer,
  customerState: customerReducer,
  categoryState: categoryReducer,
  orderDetailState: orderDetailReducer,
  lotProductState: LotProductReducer,
  collectState: CollectReducer,
  externalMappingState: externalMappingReducer,
  pdfTrackingState: pdfTrackingReducer,
  noteState: noteReducer,
  addressCardinal: addressCardinalReducer,
  addressInsideType: addressInsideTypeReducer,
  addressNomenclature: addressNomenclatureReducer,
  addressRoadType: addressRoadTypeReducer,
  addressCloseConfirmation: addressCloseConfirmationReducer,
  statusState: StatusReducer,
  financialState: financialReducer,
  auditoriaState: auditoriaNotificationsReducer,
  branchState: branchReducer,
  fdxCartaPorteLogs: fdxCartaPorteReducer,
  statusesState: statusesReducer,
  ordersLocked: OrdersLockedReducer,
  assignOperatorState: assignOperatorReducer,
  warehouseState: warehouseReducer,
  reschedulingReducer: reschedulingReducer,
  inventoryState: inventoryReducer,
  coverageState: coverageReducer,
  originState: originReducer,
  alertTypeState: alertTypeReducer,
  alertSourceState: alertSourceReducer,
  dspState: dspReducer,
  dspOrderAssignCourier: dspOrderAssignCourierReducer,
  wmsState: wmsReducer,
  preOrderState: preOrderReducer,
  investDataState: investDataReducer, 
});
