const initialState = {
  isAuthenticated: false,
  session: null,
  requestingRefreshToken: false,
  successfulRefreshToken: false,
  errorsRefreshToken: false,
  token: {},
  validateRefreshToken: true,
  errorsLogOut: false,
  requestingLogOut: false,
  successfulLogOut: false,
  requestingResetPassword: false,
  successfulResetPassword: false,
  errorsResetPassword: false,
  requestingMassiveResetPassword: false,
  successfulMassiveResetPassword: false,
  errorsMassiveResetPassword: false,
}

export const getInitialState = () => JSON.parse(JSON.stringify(initialState));

const authReducer = (
  state = getInitialState(),
  action
) => {
  switch (action.type) {
    case "SIGN_IN":
      return Object.assign({}, state, {
        isAuthenticated: true,
        session: action.payload,
      });
    case "RESTORE_SESION":
      return Object.assign({}, state, {
        isAuthenticated: true,
        session: action.payload,
      });
    case "SIGN_OUT":
      return getInitialState();
    default:
      return state;
    case "REFRESH_TOKEN_REQUESTING":
      return {
        ...state,
        requestingRefreshToken: true,
        successfulRefreshToken: false,
        errorsRefreshToken: false,
      };
    case "REFRESH_TOKEN_SUCCESS":
      return {
        ...state,
        errorRefreshToken: false,
        requestingRefreshToken: false,
        successfulRefreshToken: true,
        token: action.value,
      };
    case "REFRESH_TOKEN_ERROR":
      return {
        ...state,
        errorRefreshToken: true,
        requestingRefreshToken: false,
        successfulRefreshToken: false,
      };
    case "SET_TOKEN_VALIDATE":
      return {
        ...state,
        validateRefreshToken: action.value
      }
    case "RESET_PASSWORD_REQUESTING":
      return {
        ...state,
        requestingResetPassword: true,
        successfulResetPassword: false,
        errorsResetPassword: false,
      };
    case "RESET_PASSWORD_SUCCESS":
      return {
        ...state,
        requestingResetPassword: false,
        successfulResetPassword: true,
        errorResetPassword: false,
      };
    case "RESET_PASSWORD_ERROR":
      return {
        ...state,
        requestingResetPassword: false,
        successfulResetPassword: false,
        errorResetPassword: true,
      };
    case "MASSIVE_RESET_PASSWORD_REQUESTING":
      return {
        ...state,
        requestingMassiveResetPassword: true,
        successfulMassiveResetPassword: false,
        errorsMassiveResetPassword: false,
      };
    case "MASSIVE_RESET_PASSWORD_SUCCESS":
      return {
        ...state,
        requestingMassiveResetPassword: false,
        successfulMassiveResetPassword: true,
        errorMassiveResetPassword: false,
      };
    case "MASSIVE_RESET_PASSWORD_ERROR":
      return {
        ...state,
        requestingMassiveResetPassword: false,
        successfulMassiveResetPassword: false,
        errorMassiveResetPassword: true,
      };
    case "FLUSH_PASSWORD_RESET_REQUESTS":
      return {
        ...state,
        requestingResetPassword: false,
        successfulResetPassword: false,
        errorsResetPassword: false,
        requestingMassiveResetPassword: false,
        successfulMassiveResetPassword: false,
        errorsMassiveResetPassword: false,
      }
    case "LOG_OUT_REQUESTING":
      return {
        ...state,
        requestingLogOut: true,
        successfulLogOut: false,
        errorsLogOut: false,
      };
    case "LOG_OUT_SUCCESS":
      return {
        ...state,
        errorLogOut: false,
        requestingLogOut: false,
        successfulLogOut: true,
      };
    case "LOG_OUT_ERROR":
      return {
        ...state,
        errorLogOut: true,
        requestingLogOut: false,
        successfulLogOut: false,
      };
  }
}

export default authReducer;
