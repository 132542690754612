import React, { forwardRef } from "react";

const GuideScanner = forwardRef(({ onScan, ...props }, ref) => {

    const COORD_REGEX = /^7(\d{8,})001$/;
    const FEDEX_REGEX = /^10134861\d{12}(\d+)$/;
    
    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            let value = event.target.value.toUpperCase();
            const coordMatch = value.match(COORD_REGEX);
            const fedexMatch = value.match(FEDEX_REGEX);

            switch(true) {
                case coordMatch !== null:
                    value = coordMatch[1];
                    break;
                case fedexMatch !== null:
                    value = fedexMatch[1].replace(/^0+/, '');
                    break;
                default:
                    break;
            }
            onScan(value);
            event.target.value = "";
        }
    };

    return (
        <>
            <input
                {...props}
                type='text'
                className='form-control'
                placeholder='Escanee la Guía'
                onKeyDown={handleKeyDown}
                ref={ref}
            />
        </>
    )
});

export default GuideScanner;