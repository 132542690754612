import {call, put, takeLatest} from "redux-saga/effects";
import request from "../../utils/request";

function* getAllUserTypes(payload) {
  try {
    yield put({
      type: "GET_ALL_USER_TYPES_REQUESTING",
    });

    yield put({
      type: "SHOW_LOADING",
    });

    const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/userTypes?page=${payload.value.page}&search=${payload.value.search}&offset=${payload.value.offset}${payload.value.isActive ? '&isActive=' + payload.value.isActive: '' }`;
    const headers = {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      }),
    };
    const response = yield call(request, requestURL, headers);

    yield put({
      type: "GET_ALL_USER_TYPES_SUCCESS",
      value: response,
    });
    yield put({
      type: "HIDE_LOADING",
    });
  } catch (error) {
    yield put({
      type: "HIDE_LOADING",
    });
    yield put({
      type: "SHOW_ALERT",
      value: {
        type: "danger",
        title: "Failed load",
        message: "Failed load Users",
      },
    });
    yield put({
      type: "GET_ALL_USER_TYPES_ERROR",
    });
  }
}

export function* watchUserType() {
  yield takeLatest("GET_ALL_USER_TYPES", getAllUserTypes);
}
