import { call, put, takeLatest } from 'redux-saga/effects';
import request from '../../utils/request';

function* fetchInvestData(action) {
    const { payload } = action;
    try {
      yield put({ type: 'SHOW_LOADING' });
  
      const requestURL = `${process.env.REACT_APP_API_URL}/api/v1/security/investData` +
        `?page=${payload.page}` +
        `&offset=${payload.offset}` +
        `${payload.search ? `&search=${encodeURIComponent(payload.search)}` : ''}` +
        `${payload.from ? `&from=${encodeURIComponent(payload.from)}` : ''}` +
        `${payload.to ? `&to=${encodeURIComponent(payload.to)}` : ''}` +
        `${payload.senderIds ? `&senderIds=${encodeURIComponent(payload.senderIds)}` : ''}` +
        `${payload.operatorIds ? `&operatorIds=${encodeURIComponent(payload.operatorIds)}` : ''}` +
        `${payload.idBranch ? `&idBranch=${encodeURIComponent(payload.idBranch)}` : ''}` +
        `${payload.idCountry ? `&idCountry=${encodeURIComponent(payload.idCountry)}` : ''}` +
        `${payload.idDepartment ? `&idDepartment=${encodeURIComponent(payload.idDepartment)}` : ''}` +
        `${payload.idCity ? `&idCity=${encodeURIComponent(payload.idCity)}` : ''}` +
        `${payload.returnTrackingId ? `&returnTrackingId=${encodeURIComponent(payload.returnTrackingId)}` : ''}`;
  
  
      const headers = {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${window.localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        }),
      };
  
      const response = yield call(request, requestURL, headers);
  
      yield put({ type: 'HIDE_LOADING' });
      yield put({ type: 'FETCH_INVEST_DATA_SUCCESS', payload: response });
    } catch (error) {
      yield put({ type: 'HIDE_LOADING' });
      yield put({
        type: 'SHOW_ALERT',
        payload: {
          type: 'danger',
          title: 'Failed to load',
          message: 'Failed to load invest data',
        },
      });
      yield put({ type: 'FETCH_INVEST_DATA_ERROR' });
    }
  }
  
  
export function* watchFetchInvestData() {
  yield takeLatest('FETCH_INVEST_DATA_REQUESTING', fetchInvestData);
}

export default watchFetchInvestData;
