import React, { useEffect, useState, useContext, useRef } from "react";
import Select from 'react-select'
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import Sidebar from "../../../components/Navigation/Sidebar"
import Topbar from "../../../components/Navigation/Topbar";
import Header from "../../../components/Page/header";
import { buttonIcon, buttonTypes } from "../../../utils/buttonsUtil";
import CollapsedSection from '../../../components/CollapsedSection'
import { Controller, useForm } from "react-hook-form";
import { isNullOrUndefined,hasNoCountryLimitations } from "../../../utils/functionsUtils";

const NewInventoryAdjustment = ({
    reduxGetWarehouses,
    reduxGetInventory,
    reduxGetClients,
    reduxResetInventoryValues,
    reduxAddNewAdjustment,
    reduxResetNewAdjustmentForm,
    reduxGetCompaniesByWarehouse,
    warehouses,
    inventory,
    shippers,
    successfulFetchAdjusment,
    companiesbyWarehouse,
}) => {

    const {
        handleSubmit: handleSubmitHeader,
        control: controlHeader,
        formState: { errors: errorsHeader, isValid: isValidHeader },
        watch: watchHeader,
        getValues: getValuesHeader,
        reset: resetHeader,
    } = useForm({
        defaultValues: {
            warehouse: null,
            movementSubType: null,
            adjustmentReason: null,
        }
    });

    const {
        register: registerDetail,
        handleSubmit: handleSubmitDetail,
        control: controlDetail,
        formState: { errors: errorsDetails, isValid: isValidDetail },
        watch: watchDetail,
        getValues: getValuesDetail,
        reset: resetDetail,
        clearErrors: clearErrorsDetail
    } = useForm({
        defaultValues: {
            shipper: null,
            product: null,
            adjustmentAmount: null,
        }
    });

    const history = useHistory();
    const [activeSectionKey, setActiveSectionKey] = useState("newAdjustmentKey");
    const [currentStockAvailable, setCurrentStockAvailable] = useState(null);
    const [finalStockAvailable, setFinalStockAvailable] = useState(null);
    const [tableBodyElements, setTableBodyElements] = useState([]);
    const [filteredInventory, setFilteredInventory] = useState();

    const movementSubTypeWatcher = watchHeader('movementSubType', "");

    useEffect(() => {
        reduxGetWarehouses({
            page: 1,
            offset: 1000,
        });

        reduxGetClients();
    }, []);

    useEffect(() => {
        if (successfulFetchAdjusment) {
            history.push("/wms/adjustments-handler");
            reduxResetNewAdjustmentForm();
        }
    }, [successfulFetchAdjusment, history]);

    useEffect(() => {
        if (inventory?.items) {
            setFilteredInventory(inventory);
        }
    }, [inventory]);

    const userData = JSON.parse(window.localStorage.getItem('userData'));
    const countryUser = userData?.idCountry;
    const viewAllCountriesData = hasNoCountryLimitations();
    const selectedIdProductsRef = useRef([]);

    const handleToggleSection = (sectionKey) => {
        const warehouseWatcher = watchHeader('warehouse', null);
        const adjustmentReasonWatcher = watchHeader('adjustmentReason', null);

        if (sectionKey === "newAdjustmentDetail"
            && (isNullOrUndefined(warehouseWatcher)
                || isNullOrUndefined(movementSubTypeWatcher)
                || isNullOrUndefined(adjustmentReasonWatcher))) {
            handleSubmitHeader((data) => { })();
            return;
        }

        setActiveSectionKey(prevState => (prevState === sectionKey ? null : sectionKey))
    }

    const movementsTypes = [
        { value: "INCOMING", label: "Ingreso", code: 1 },
        { value: "OUTGOING", label: "Salida", code: 2 },
    ];

    const adjustmentSubType = [
        { value: "ADJUSTMENT_QTY", label: "Ajuste por diferencia de cantidades", code: 'ADJUSTMENT_QTY' },
        { value: "ADJUSTMENT_CLI", label: "Ajuste por petición de cliente", code: 'ADJUSTMENT_CLI' },
    ];

    const tableHeaders = [
        "Bodega",
        "Remitente",
        "Producto",
        "Tipo de Ajuste",
        "Cantidad del ajuste",
        "Acciones"
    ];

    const actionButtons = [
        {
            onClick: () => history.push("/wms/adjustments-handler"),
            description: "Regresar a movimientos de inventario",
            buttonType: buttonTypes.Primary,
            buttonIcon: buttonIcon.Arrow_LeftReply,
        },
    ];

    const handleClickAddAdjustment = () => {
        handleSubmitDetail((data) => { })();

        const headerFormValues = getValuesHeader();
        const detailFormValues = getValuesDetail();
        const { warehouse, movementSubType  } = headerFormValues;
        const { shipper, product, adjustmentAmount } = detailFormValues

        for (let key in detailFormValues) {
            if (
                detailFormValues[key] === null || 
                detailFormValues[key] === "" || 
                (key === 'adjustmentAmount' && detailFormValues[key] < 1)
            ) {
                return false;
            }
        }

        selectedIdProductsRef.current = [...selectedIdProductsRef.current, product.value]

        if (finalStockAvailable < 0) {
            Swal.fire('Error', 'La cantidad del ajuste supera el stock disponible.', 'error');
            return;
        }

        const newAdjustment = {
            warehouse,
            shipper,
            product,
            finalStockAvailable,
            adjustmentAmount: Number(adjustmentAmount),
        };

        setTableBodyElements(prevElements => [...prevElements, newAdjustment]);

        setFilteredInventory({
            ...inventory,
            items: inventory.items.filter(item => !selectedIdProductsRef.current.includes(item.idProduct)),
        });

        resetFields("addedAdjustment");
    }

    const handleChangeAdjustmentAmount = (event) => {
        const movementSubType = watchHeader('movementSubType', null);
        const adjustmentAmount = watchDetail('adjustmentAmount', null);

        if (isNullOrUndefined(adjustmentAmount)) { clearErrorsDetail("adjustmentAmount") }

        if (movementSubType?.value !== "") {
            setFinalStockAvailable(Number(currentStockAvailable) - Number(event.target.value));
        }
    }

    const onSubmitSendAdjustment = (data) => {
        const detailFormValues = getValuesDetail();
        const warehouseWatcher = watchHeader('warehouse', null);

        const { shipper, product, adjustmentAmount } = detailFormValues

        if ((isNullOrUndefined(shipper) || isNullOrUndefined(product) || isNullOrUndefined(adjustmentAmount))
            && tableBodyElements.length <= 0) {
            handleSubmitDetail((data) => { })();
            return
        }
        
        clearErrorsDetail("product");
        clearErrorsDetail("adjustmentAmount");

        const adjustmentReasonWatcher = watchHeader('adjustmentReason', null);

        if (tableBodyElements.length <= 0) {
            Swal.fire('Error', 'Debe agregar al menos un ajuste a la lista.', 'error');
            return;
        }

        const adjustmentDetail = tableBodyElements.map((adjElement) => (
            {
                idShipper: adjElement.shipper.value,
                quantity: adjElement.adjustmentAmount,
                warehouseId: adjElement.warehouse.value,
                productId: adjElement.product.value
            }
        ));

        const adjustmentsPayload = {
            adjustmentReason: adjustmentReasonWatcher,
            idAdjustmentSubType: movementSubTypeWatcher.code,
            idWarehouse: warehouseWatcher.value,
            adjustmentDetail
        };

        reduxAddNewAdjustment(adjustmentsPayload);
    }

    const searchProducts = () => {
        const warehouseWatcher = watchHeader('warehouse', null);
        const shipperClientWatcher = watchDetail('shipper', null);
        resetFields("shipper");

        if (!isNullOrUndefined(shipperClientWatcher)) {
            reduxGetInventory({
                page: 1,
                offset: 1000,
                Search: "",
                shipper: shipperClientWatcher ? shipperClientWatcher.value : null,
                warehouses: warehouseWatcher ? warehouseWatcher.value : null,
            });
        }
    }

    const handleChangeWarehouse = (selectedOption) => {
        if (!isNullOrUndefined(selectedOption)) {
            reduxGetCompaniesByWarehouse(selectedOption.value)
        }

        resetFields("warehouse");
    }

    const handleChangeMovementSubType = (selectedOption) => {
        resetFields("movementSubType");
    }

    const handleChangeProduct = (selectedOption) => {
        setCurrentStockAvailable(!isNullOrUndefined(selectedOption) ? 
            selectedOption.availableStock - selectedOption.reservedStock : 
            null);
        resetFields("product");
    }

    const handleDeleteAdjustment = (indexToRemove) => {
        const deletedAjustment = tableBodyElements.find((_item, index) => index === indexToRemove);

        selectedIdProductsRef.current = selectedIdProductsRef.current.filter(el => el != deletedAjustment.product.value)

        setFilteredInventory({
            ...inventory,
            items: inventory.items.filter(item => !selectedIdProductsRef.current.includes(item.idProduct)),
        });

        setTableBodyElements((prevElements) => prevElements.filter((_, index) => index !== indexToRemove));
    }

    const resetFields = (fromField) => {
        const currentformHeaderValues = getValuesHeader();
        const currentformDetailValues = getValuesDetail();

        switch (fromField) {
            case "warehouse":
                resetHeader({ ...currentformHeaderValues, movementSubType: null })

                resetDetail({
                    shipper: null,
                    product: null,
                    adjustmentAmount: null
                });
                setCurrentStockAvailable(null);
                setFinalStockAvailable(null);
                reduxResetInventoryValues();
                break;
            case "movementSubType":
                resetDetail({
                    shipper: null,
                    product: null,
                    adjustmentAmount: null,
                });
                setCurrentStockAvailable(null);
                setFinalStockAvailable(null);
                setFilteredInventory({});
                setTableBodyElements([]);
                break;
            case "shipper":
                resetDetail({
                    ...currentformDetailValues,
                    product: null,
                    adjustmentAmount: null
                });
                setCurrentStockAvailable(null);
                setFinalStockAvailable(null);
                break;
            case "product":
                resetDetail({
                    ...currentformDetailValues,
                    adjustmentAmount: null
                });
                setFinalStockAvailable(null);
                break;
            case "addedAdjustment":
                resetDetail({
                    ...currentformDetailValues,
                    product: null,
                    adjustmentAmount: null
                });
                setCurrentStockAvailable(null);
                setFinalStockAvailable(null);
                break;

            default:
                break;
        }
    }

    return (
        <>
            <div id="wrapper">
                <Sidebar />
                <div id="content-wrapper" className="d-flex flex-column">
                    <Topbar />
                    <div className="container-fluid">
                        <Header title={"Ajustes de inventario"} subtitle={"Módulo para la creación de ajustes de inventario"} actionButtons={actionButtons} />
                        <div className="card shadow mb-4">
                            <CollapsedSection
                                sectionKey="newAdjustmentKey"
                                title="Información principal"
                                show={activeSectionKey === 'newAdjustmentKey'}
                                onToggle={() => handleToggleSection("newAdjustmentKey")}
                            >
                                <form>
                                    <div className="row d-flex flex-gap-1">
                                        <div className='form-group col-md-5'>
                                            <label
                                                htmlFor='warehouse'
                                                className='form-label'>
                                                Bodega
                                            </label>
                                            <Controller
                                                control={controlHeader}
                                                name="warehouse"
                                                rules={{ required: 'La bodega es requerida' }}
                                                render={({ field }) => (
                                                    <>
                                                        <Select
                                                            {...field}
                                                            isClearable
                                                            options={
                                                                warehouses && warehouses.items
                                                                    ? warehouses.items
                                                                        .filter(e => e.isActive === true && 
                                                                            (viewAllCountriesData || e.warehouseHubs[0]?.hub?.idCountry == countryUser && countryUser))
                                                                        .map(ele => ({
                                                                            value: ele.idWarehouse,
                                                                            label: ele.name || ele.warehouseCode,
                                                                        }))
                                                                    : []
                                                            }
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption);
                                                                handleChangeWarehouse(selectedOption);
                                                            }}
                                                        />
                                                        {
                                                            errorsHeader.warehouse &&
                                                            <span className="error-message">
                                                                {errorsHeader.warehouse.message}
                                                            </span>
                                                        }
                                                    </>
                                                )}
                                            />
                                        </div>
                                        <div className="form-group col-md-3">
                                        <label htmlFor="movementSubType" className="form-label">
                                            Tipo Ajuste
                                        </label>
                                        <Controller
                                            control={controlHeader}
                                            name="movementSubType"
                                            rules={{ required: 'Este campo es requerido' }}
                                            render={({ field }) => (
                                            <>
                                                <Select
                                                {...field}
                                                isClearable
                                                options={adjustmentSubType}
                                                onChange={(selectedOption) => {
                                                    field.onChange(selectedOption);
                                                    handleChangeMovementSubType(selectedOption);
                                                }}
                                                />
                                                {errorsHeader.movementSubType && (
                                                <span className="error-message">{errorsHeader.movementSubType.message}</span>
                                                )}
                                            </>
                                            )}
                                        />
                                        </div>
                                        
                                    </div>
                                    <div className="row">
                                        <div className='form-group col-md-9'>
                                            <Controller
                                                control={controlHeader}
                                                name="adjustmentReason"
                                                rules={{
                                                    required: 'El campo de razón del ajuste es requerido'
                                                }}
                                                render={({ field }) => (
                                                    <>
                                                        <label
                                                            htmlFor='adjustmentReason'
                                                            className='form-label'>
                                                            Razón del ajuste de inventario
                                                        </label>
                                                        <textarea
                                                            {...field}
                                                            className={`form-control`}
                                                            type="textarea"
                                                        />
                                                        {
                                                            errorsHeader.adjustmentReason &&
                                                            <span className="error-message">
                                                                {errorsHeader.adjustmentReason.message}
                                                            </span>
                                                        }
                                                    </>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </CollapsedSection>

                            <CollapsedSection
                                sectionKey="newAdjustmentDetail"
                                title="Detalle del ajuste"
                                show={activeSectionKey === 'newAdjustmentDetail'}
                                onToggle={() => handleToggleSection("newAdjustmentDetail")}
                            >
                                <form>
                                    <div className="row">
                                        <div className="form-group col-md-3">
                                        <label htmlFor="shipper" className="form-label">
                                                Remitente
                                            </label>
                                            <Controller
                                                control={controlDetail}
                                                name="shipper"
                                                rules={{ required: 'El remitente es requerido' }}
                                                render={({ field }) => (
                                                    <>
                                                        <Select
                                                            {...field}
                                                            isClearable
                                                            options={
                                                                companiesbyWarehouse && Object.keys(companiesbyWarehouse).length > 0
                                                                    ? companiesbyWarehouse.items
                                                                        .filter(e => e.isActive === true && (viewAllCountriesData || e.country == countryUser && countryUser))
                                                                        .map((ele, key) => ({
                                                                            value: ele.idCompany,
                                                                            label: ele.description,
                                                                        }))
                                                                    : []
                                                            }
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption);
                                                                searchProducts(selectedOption);
                                                            }}
                                                        />
                                                        {errorsDetails.shipper && (
                                                            <span className="error-message">{errorsDetails.shipper.message}</span>
                                                        )}
                                                    </>
                                                )}
                                            />
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="product" className="form-label">
                                                Producto
                                            </label>
                                            <Controller
                                                control={controlDetail}
                                                name="product"
                                                rules={{ required: 'El producto es requerido' }}
                                                render={({ field }) => (
                                                    <>
                                                        <Select
                                                            {...field}
                                                            isClearable
                                                            options={
                                                                filteredInventory && Object.keys(filteredInventory).length > 0
                                                                    ? filteredInventory.items
                                                                        .map((el, key) => ({
                                                                            value: el.idProduct,
                                                                            label: el.name,
                                                                            availableStock: el.availableStock,
                                                                            reservedStock: el.reservedStock
                                                                        }))
                                                                    : []
                                                            }
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption);
                                                                handleChangeProduct(selectedOption);
                                                            }}
                                                        />
                                                        {errorsDetails.product && (
                                                            <span className="error-message">{errorsDetails.product.message}</span>
                                                        )}
                                                    </>
                                                )}
                                            />
                                        </div>

                                        <div className="form-group col-md-2 text-center mb-0">
                                            <label>Cantidad disponible</label>
                                            <p>{currentStockAvailable}</p>
                                        </div>

                                        <div className="form-group col-md-3">
                                            <label htmlFor="adjustmentAmount" className="form-label">
                                                Cantidad del ajuste
                                            </label>
                                            <input
                                                id="adjustmentAmount"
                                                type="number"
                                                className={`form-control form-control-user ${errorsDetails.adjustmentAmount ? "is-invalid" : ""}`}
                                                {...registerDetail("adjustmentAmount", { 
                                                    required: "La cantidad es requerida",
                                                    min: {
                                                        value: 1,
                                                        message: "La cantidad minima permitida es 1."
                                                    }
                                                })}
                                                onChange={(e) => handleChangeAdjustmentAmount(e)}
                                                onKeyDown={(e) => {
                                                    if (e.key === "-") { e.preventDefault() }
                                                }}
                                            />

                                            {errorsDetails.adjustmentAmount && (
                                                <span className="invalid-feedback">{errorsDetails.adjustmentAmount.message}</span>
                                            )}
                                        </div>

                                        <div className="form-group col-md-1 d-flex justify-content-center align-items-center mb-0">
                                            <Button
                                                title="Agregar ajuste a la tabla"
                                                variant='primary'
                                                type='button'
                                                className="rounded-circle"
                                                onClick={handleClickAddAdjustment}>
                                                <i className={buttonIcon.Add}></i>
                                            </Button>
                                        </div>
                                    </div>

                                    <table className="table table-bordered dataTable table-sm ">
                                        <thead>
                                            <tr>
                                                {tableHeaders.map((header, index) => (
                                                    <th key={index}>{header}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableBodyElements.map((aElement, index) => (
                                                <tr key={index}>
                                                    <td>{aElement.warehouse.label}</td>
                                                    <td>{aElement.shipper.label}</td>
                                                    <td>{aElement.product.label}</td>
                                                    <td>{movementSubTypeWatcher?.label}</td>
                                                    <td>{aElement.adjustmentAmount}</td>
                                                    <td className="d-flex justify-content-center align-items-center">
                                                        <Button
                                                            variant="danger"
                                                            type="button"
                                                            title="Remover ajuste"
                                                            className="btn-sm rounded-circle"
                                                            onClick={() => handleDeleteAdjustment(index)}
                                                        >
                                                            <i className={buttonIcon.Trash}></i>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                    <div className='d-flex justify-content-center mt-2'>
                                        <Button
                                            onClick={onSubmitSendAdjustment}
                                            variant='primary'
                                            type="button">
                                            Confirmar
                                        </Button>
                                    </div>
                                </form>

                            </CollapsedSection>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        shippers: state.companyState.clients,
        warehouses: state.warehouseState.warehouses,
        inventory: state.inventoryState.inventory,
        successfulFetchAdjusment: state.adjustmentState.successfulFetchAdjusment,
        companiesbyWarehouse: state.companyState.companiesbyWarehouse,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        reduxGetClients: (payload) => {
            dispatch({
                type: 'FETCH_COMPANIESBYSHIPPER_REQUEST',
                value: payload,
            });
        },
        reduxGetWarehouses: (payload) => {
            dispatch({
                type: "FETCH_WAREHOUSES_REQUEST",
                value: payload,
            });
        },
        reduxGetInventory: (payload) => {
            dispatch({
                type: "READ_INVENTORY_REQUEST",
                value: payload,
            });
        },
        reduxResetInventoryValues: () => {
            dispatch({
                type: "RESET_WAREHOUSE_INVENTORY_FORM"
            });
        },
        reduxAddNewAdjustment: (payload) => {
            dispatch({
                type: "FETCH_ADD_NEW_ADJUSTMENT_REQUEST",
                value: payload
            });
        },
        reduxResetNewAdjustmentForm: () => {
            dispatch({
                type: "RESET_NEW_ADJUSTMENT_FORM"
            });
        },
        reduxGetCompaniesByWarehouse: (payload) => {
            dispatch({
                type: "FETCH_COMPANIES_BYWAREHOUSE_REQUEST",
                value: payload
            });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewInventoryAdjustment);