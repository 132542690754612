import React, {
  forwardRef,
  useEffect,
  useState,
  useContext,
} from "react";
import { DateTime } from 'luxon';
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import Select from 'react-select'

import { Show } from "../../../hooks/Show";
import { PermissionContext } from "../../../hooks/PermissionContext";

import Sidebar from "../../../components/Navigation/Sidebar";
import Topbar from "../../../components/Navigation/Topbar";
import Grid from "../../../components/Grid";

import formatDateWithCustomFormat, { FormatsDate } from "../../../utils/formatDate";
import { getStatusBadgeByCode } from "../../../utils/StatusUtils";

const Swal = require("sweetalert2");

const ReschedulingsReport = ({
  reduxGetCompanies,
  companies,
  reduxGetAddresses,
  addresses,
  reduxResetAddressForm,
}) => {
  const {
    register: registerSearch,
    handleSubmit: handleSubmitSearch,
    formState: { errors: errorsSearch },
    reset: resetSearch,
    setValue: setValueSearch,
    getValues: getValuesSearch,
  } = useForm({});

  const [startDateFrom, setStartDateFrom] = useState(null);
  const [startDateTo, setStartDateTo] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(10);
  const [search, setSearch] = useState("");
  const { hasPermission } = useContext(PermissionContext);

  const DatePickerCustomFrom = forwardRef(
    ({ value, onClick, onChange }, ref) => (
      <>
        <input
          className={`form-control form-control-user ${errorsSearch.from && "is-invalid"
            }`}
          onClick={onClick}
          onChange={onChange}
          ref={ref}
          value={value}
          autoComplete="Off"
          {...registerSearch("from", {
            required: getValuesSearch("delivery") ? false : true,
          })}
        />
        {!getValuesSearch("delivery") && errorsSearch.from && (
          <span className="invalid-feedback">Fecha inicial es requerida</span>
        )}
      </>
    )
  );
  
  const DatePickerCustomTo = forwardRef(({ value, onClick, onChange }, ref) => (
    <>
      <input
        className={`form-control form-control-user ${errorsSearch.to && "is-invalid"
          }`}
        onClick={onClick}
        onChange={onChange}
        ref={ref}
        value={value}
        autoComplete="Off"
        {...registerSearch("to", {
          required: getValuesSearch("delivery") ? false : true,
        })}
      />
      {!getValuesSearch("delivery") && errorsSearch.to && (
        <span className="invalid-feedback">Fecha final es requerida</span>
      )}
    </>
  ));
  const DatePickerCustomDelivery = forwardRef(
    ({ value, onClick, onChange }, ref) => (
      <>
        <input
          className={`form-control form-control-user ${errorsSearch.to && "is-invalid"
            }`}
          onClick={onClick}
          onChange={onChange}
          ref={ref}
          value={value}
          autoComplete="Off"
          {...registerSearch("delivery", {
            required:
              getValuesSearch("from") && getValuesSearch("to") ? false : true,
          })}
        />
        {!getValuesSearch("from") && !getValuesSearch("to") && errorsSearch.delivery && (
          <span className="invalid-feedback">
            Fecha de entrega es requerida
          </span>
        )}
      </>
    )
  );
  const handledChangeFrom = (date) => {
    setStartDateFrom(date);
    setValueSearch(
      "from",
      moment(date).tz("America/Bogota").format("MM-DD-YYYY")
    );
  };
  const handledChangeTo = (date) => {
    setStartDateTo(date);
    setValueSearch(
      "to",
      moment(date).tz("America/Bogota").format("MM-DD-YYYY")
    );
  };
  const handledChangeDelivery = (date) => {
    setDeliveryDate(date);
    setValueSearch(
      "delivery",
      moment(date).tz("America/Bogota").format("MM-DD-YYYY")
    );
  };

  const onSubmitSearch = (data) => {
    if (startDateTo < startDateFrom) {
      Swal.fire(
        "Información",
        "La fecha inicial debe ser  menor a la fecha final , intenta de nuevo",
        "info"
      );
      return;
    }
    let filters = {
      page: 1,
      search: search ? search : "",
      offset: offset,
    };
    if (getValuesSearch("idClient")) {
      filters = {
        ...filters,
        idClient: getValuesSearch("idClient"),
      };
    }
    if (getValuesSearch("idOperator")) {
      filters = {
        ...filters,
        idOperator: getValuesSearch("idOperator"),
      };
    }
    if (getValuesSearch("from") && getValuesSearch("to")) {
      filters = {
        ...filters,
        from: formatDateWithCustomFormat(getValuesSearch("from"), { format: FormatsDate.FULL24 }),
        to: formatDateWithCustomFormat(getValuesSearch("to"), { format: "YYYY-MM-DD 23:59:59" }),
      };
    }
    if (getValuesSearch("delivery")) {
      filters = {
        ...filters,
        delivery: moment(getValuesSearch("delivery"))
          .tz("America/Bogota")
          .format("YYYY-MM-DD"),
      };
    }
    reduxGetAddresses(filters);
  };

  const columns = [
    {
      title: "Id dirección",
      render: (rowData) => {
        return <span>{rowData.idAddress}</span>;
      },
      field: "idAddress",
      searchable: true,
      visible: true,
    },
    {
      title: "Ciudad",
      render: (rowData) => {
        return <span>{rowData.city}</span>;
      },
      field: "city",
      searchable: true,
      visible: hasPermission('feature:rescheduling-show-column-city')
    },
    {
      title: "Remitente",
      render: (rowData) => {
        return <span>{rowData.shipper}</span>;
      },
      visible: hasPermission('feature:show-client-address')
    },
    {
      title: "Operador",
      render: (rowData) => {
        return <span>{rowData.operator}</span>;
      },
      visible: hasPermission('feature:show-operator-address')
    },
    {
      title: "Guía",
      render: (rowData) => {
        return <span>{rowData.trackingId}</span>;
      },
      field: "trackingId",
      searchable: true,
      visible: hasPermission('feature:rescheduling-show-column-trackingid')
    },
    {
      title: "Dirección Destinatario",
      render: (rowData) => {
        return <span>{rowData.address}</span>;
      },
      field: "address",
      searchable: true,
      visible: hasPermission('feature:show-recipient-address')
    },
    {
      title: "Valor a recaudar",
      render: (rowData) => {
        return <span>{rowData.collection}</span>;
      },
      field: "collection",
      searchable: true,
      visible: hasPermission('feature:rescheduling-show-column-collection')
    },
    {
      title: "Estado",
      render: (rowData) => { 
        return getStatusBadgeByCode(rowData.statusCode) 
      },
      field: "state",
      searchable: true,
      visible: hasPermission('feature:rescheduling-show-column-state')
    },
    {
      title: "Intentos de entrega",
      render: (rowData) => {
        return <span>{rowData.delivery_attempt_count}</span>;
      },
      field: "collection",
      searchable: true,
      visible: hasPermission('feature:rescheduling-show-column-attempts')
    },
    {
      title: "Fecha Estado",
      render: (rowData) => {
        return (
          <span>
            {rowData.lastUpdateDate}
          </span>
        );
      },
      visible: hasPermission('feature:rescheduling-show-column-lastupdatedate')
    },
    {
      title: "Fecha Gestión",
      render: (rowData) => {
        return (
          <span>
            {rowData.lastUpdateDate}
          </span>
        );
      },
      visible: true
    },
    {
      title: "Fecha de Programación",
      render: (rowData) => {
        return (
          <span>
            {rowData.deliveryDate && String(rowData.deliveryDate).includes('/')
              ? DateTime.fromFormat(String(rowData.deliveryDate), "YYYY/MM/dd").toFormat('dd-MM-YYYY')
              : rowData.deliveryDate
                ? String(rowData.deliveryDate)
                : ''}
          </span>
        );
      },
      visible: hasPermission('feature:rescheduling-show-column-rescheduleddate')
    },
    {
      title: "Tiempo de semáforo",
      render: (rowData) => {
        return (
          <span>
            {rowData.daysTrafficLight}
          </span>
        );
      },
      visible: true
    },
    {
      title: "Solución",
      render: (rowData) => {
        return (
          <span>
            {rowData.rescheduleResolution}
          </span>
        );
      },
      visible: true
    },
    {
      title: "Observaciones de reprogramación",
      render: (rowData) => {
        return (
          <span>
            {rowData.observations}
          </span>
        );
      },
      visible: hasPermission('feature:rescheduling-show-column-noveltynote')
    },
    {
      title: "Usuario de Gestion",
      render: (rowData) => {
        return (
          <span>
            {rowData.managementUser}
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    reduxGetCompanies({
      page: 1,
      search: "",
      offset: 1000,
      isActive: true,
      includeAll: true,
    });
    reduxResetAddressForm();
    resetSearch();
    setValueSearch("from", null);
    setValueSearch("to", null);
    setValueSearch("delivery", null);
  }, []);

  useEffect(() => {
    if (
      currentPage &&
      addresses &&
      Object.keys(addresses).length > 0 &&
      ((getValuesSearch("from") && getValuesSearch("to")) ||
        getValuesSearch("delivery"))
    ) {
      let filters = {
        page: currentPage,
        offset: offset,
        search: search,
      };
      if (getValuesSearch("idClient")) {
        filters = {
          ...filters,
          idClient: getValuesSearch("idClient"),
        };
      }
      if (getValuesSearch("idOperator")) {
        filters = {
          ...filters,
          idOperator: getValuesSearch("idOperator"),
        };
      }
      if (getValuesSearch("from") && getValuesSearch("to")) {
        filters = {
          ...filters,
          from: formatDateWithCustomFormat(getValuesSearch("from"), { format: FormatsDate.FULL24 }),
          to: formatDateWithCustomFormat(getValuesSearch("to"), { format: "YYYY-MM-DD 23:59:59" }),
        };
      }
      if (getValuesSearch("delivery")) {
        filters = {
          ...filters,
          delivery: moment(getValuesSearch("delivery"))
            .tz("America/Bogota")
            .format("YYYY-MM-DD"),
        };
      }
      reduxGetAddresses(filters);
    }
  }, [currentPage]);

  const download = () => {
    if (startDateTo < startDateFrom) {
      Swal.fire(
        "Información",
        "La fecha inicial debe ser  menor a la fecha final , intenta de nuevo",
        "info"
      );
      return;
    }
    const token = window.localStorage.getItem("token");
    let _data = {
      from: formatDateWithCustomFormat(getValuesSearch("from"), { format: FormatsDate.SHORT }),
      to: formatDateWithCustomFormat(getValuesSearch("to"), { format: FormatsDate.SHORT }),
      delivery: getValuesSearch("delivery"),
      idClient: getValuesSearch("idClient"),
      idOperator: getValuesSearch("idOperator"),
      page: currentPage,
      offset: offset,
      search: search ? search : "",
    };

    fetch(`${process.env.REACT_APP_REPORT_URL}/api/v1/reports/rescheduled`, {
      method: "POST",
      body: JSON.stringify(_data),
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.blob())
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = moment(new Date()).tz("America/Bogota").format("YYYY-MM-DD_HHmmss") + "-rescheduled-report.csv";
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  return (
    <div>
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center mb-1">
                <h1 className="h3 mb-0 text-gray-800 mr-3">
                  Reporte de Reprogramaciones
                </h1>
              </div>
              <p className="mb-4">Módulo de Reprogramaciones</p>
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Listado de Reprogramaciones
                  </h6>
                </div>
                <div className="card-body">
                  <form onSubmit={handleSubmitSearch(onSubmitSearch)}>
                    <div className="row">
                      <div className="col s2 m2">
                        <div className="form-group">
                          <label htmlFor="from" className="form-label">
                            Fecha inicial
                          </label>
                          <div>
                          <DatePicker
                              selected={startDateFrom}
                              onChange={(date) => handledChangeFrom(date)}
                              dateFormat="MM-dd-yyyy"
                              customInput={
                                <DatePickerCustomFrom 
                                  readOnly={hasPermission('feature:reschedule-visual-client-report')}
                                />
                              }
                              minDate={
                                !hasPermission('feature:show-all-reschedule-report') ? 
                                  (hasPermission('feature:reschedule-visual-client-report') ? 
                                    moment().subtract(15, 'days').toDate() : 
                                    moment().subtract(3, 'days').toDate()) : 
                                  null
                              }
                              maxDate={
                                !hasPermission('feature:show-all-reschedule-report') ? 
                                  moment().toDate() : 
                                  null
                              }
                            /> 
                          </div>
                        </div>
                      </div>
                      <div className="col s2 m2">
                        <div className="form-group">
                          <label htmlFor="to" className="form-label">
                            Fecha final
                          </label>
                          <div>
                          <DatePicker
                              selected={startDateTo}
                              onChange={(date) => handledChangeTo(date)}
                              dateFormat="MM-dd-yyyy"
                              customInput={
                                <DatePickerCustomTo 
                                  readOnly={hasPermission('feature:reschedule-visual-client-report')}
                                />
                              }
                              minDate={
                                !hasPermission('feature:show-all-reschedule-report') ? 
                                  (hasPermission('feature:reschedule-visual-client-report') ? 
                                    moment().subtract(15, 'days').toDate() : 
                                    moment(startDateFrom).toDate()) : 
                                  null
                              }
                              maxDate={
                                !hasPermission('feature:show-all-reschedule-report') ? 
                                  moment().toDate() : 
                                  null
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col s2 m2">
                        <div className="form-group">
                          <label htmlFor="delivery" className="form-label">
                            Fecha de entrega
                          </label>
                          <div>
                            <DatePicker
                              selected={deliveryDate}
                              onChange={(date) => handledChangeDelivery(date)}
                              dateFormat="MM-dd-yyyy"
                              customInput={<DatePickerCustomDelivery />}
                            />
                          </div>
                        </div>
                      </div>
                      <Show when="feature:show-client-address">
                        <div className="form-group col-md-2">
                          <label htmlFor="idClient" className="form-label">
                            Remitente
                          </label>
                          <Select
                            isDisabled={!startDateFrom || !startDateTo}
                            isClearable={true}
                            isMulti={true}
                            {...registerSearch('idClient')}
                            id='idClient'
                            onChange={(value) => {
                              const selectedValues = value ? value.map(item => item.value) : [];
                              setValueSearch('idClient', selectedValues);
                            }}
                            options={
                              companies &&
                              Object.keys(companies).length > 0 &&
                              companies.items.map((ele) => ({
                                value: ele.idCompany,
                                label: ele.description,
                              }))
                            }
                          />
                        </div>
                      </Show>
                      <Show when="feature:show-operator-address">
                        <div className="form-group col-md-2">
                          <label htmlFor="idOperator" className="form-label">
                            Operador
                          </label>
                          <Select
                            isDisabled={!startDateFrom || !startDateTo}
                            isClearable={true}
                            {...registerSearch('idOperator')}
                            id='idOperator'
                            onChange={(value) => setValueSearch('idOperator', value?.value || '')}
                            options=
                            {companies &&
                              Object.keys(companies).length > 0 &&
                              companies.items
                                .map((ele) => ({
                                  value: ele.idCompany,
                                  label: ele.description,
                                }))
                            }
                          />
                        </div>
                      </Show>
                      <div className="col s12 m1">
                        <Button
                          variant="primary"
                          style={{ marginTop: "32px" }}
                          type="submit"
                          id="btnSearch"
                        >
                          Buscar
                        </Button>
                      </div>
                      {addresses &&
                        addresses.items &&
                        Object.keys(addresses.items).length > 0 && (
                          <div className="col s12 m1">
                            <Button
                              variant="primary"
                              style={{ marginTop: "32px" }}
                              id="btnSearch"
                              onClick={download}
                            >
                              Descargar
                            </Button>
                          </div>
                        )}
                    </div>
                  </form>
                  <Grid
                    cols={columns}
                    data={
                      addresses && Object.keys(addresses).length > 0
                        ? addresses.items
                        : []
                    }
                    total={
                      addresses && addresses.hasOwnProperty("total")
                        ? addresses.total
                        : 0
                    }
                    page={
                      addresses && Object.keys(addresses).length > 0
                        ? Number(addresses.page)
                        : currentPage
                    }
                    pages={
                      addresses && Object.keys(addresses).length > 0
                        ? Number(addresses.totalPages)
                        : 0
                    }
                    offset={offset}
                    onChangePage={(page) => setCurrentPage(page)}
                    onChangeRange={(value) => {
                      setOffset(value);
                      let filters = {
                        page: 1,
                        offset: value,
                        search: search,
                      };
                      if (getValuesSearch("idClient")) {
                        filters = {
                          ...filters,
                          idClient: getValuesSearch("idClient"),
                        };
                      }
                      if (getValuesSearch("idOperator")) {
                        filters = {
                          ...filters,
                          idOperator: getValuesSearch("idOperator"),
                        };
                      }
                      if (getValuesSearch("from") && getValuesSearch("to")) {
                        if (startDateTo < startDateFrom) {
                          Swal.fire(
                            "Información",
                            "La fecha inicial debe ser  menor a la fecha final , intenta de nuevo",
                            "info"
                          );
                          return;
                        }
                        filters = {
                          ...filters,
                          from: formatDateWithCustomFormat(getValuesSearch("from"), { format: FormatsDate.FULL24 }),
                          to: formatDateWithCustomFormat(getValuesSearch("to"), { format: "YYYY-MM-DD 23:59:59" }),
                        };
                      }
                      if (getValuesSearch("delivery")) {
                        filters = {
                          ...filters,
                          delivery: moment(getValuesSearch("delivery"))
                            .tz("America/Bogota")
                            .format("YYYY-MM-DD"),
                        };
                      }
                      reduxGetAddresses(filters);
                    }}
                    defaultValue={search}
                    onChangeSearch={(value) => {
                      setSearch(value);
                      let filters = {
                        page: 1,
                        offset: offset,
                        search: value,
                      };
                      if (getValuesSearch("idClient")) {
                        filters = {
                          ...filters,
                          idClient: getValuesSearch("idClient"),
                        };
                      }
                      if (getValuesSearch("idOperator")) {
                        filters = {
                          ...filters,
                          idOperator: getValuesSearch("idOperator"),
                        };
                      }
                      if (getValuesSearch("from") && getValuesSearch("to")) {
                        if (startDateTo < startDateFrom) {
                          Swal.fire(
                            "Información",
                            "La fecha inicial debe ser  menor a la fecha final , intenta de nuevo",
                            "info"
                          );
                          return;
                        }
                        filters = {
                          ...filters,
                          from: formatDateWithCustomFormat(getValuesSearch("from"), { format: FormatsDate.FULL24 }),
                          to: formatDateWithCustomFormat(getValuesSearch("to"), { format: "YYYY-MM-DD 23:59:59" }),
                        };
                      }
                      if (getValuesSearch("delivery")) {
                        filters = {
                          ...filters,
                          delivery: moment(getValuesSearch("delivery"))
                            .tz("America/Bogota")
                            .format("YYYY-MM-DD"),
                        };
                      }
                      reduxGetAddresses(filters);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    addresses: state.addressState.rescheduled,
    companies: state.companyState.companies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxGetCompanies: (payload) =>
      dispatch({
        type: "FETCH_COMPANIES_REQUEST",
        value: payload,
      }),
    reduxGetAddresses: (payload) =>
      dispatch({
        type: "FETCH_ADDRESSES_RESCHEDULED_REQUEST",
        value: payload,
      }),
    reduxResetAddressForm: () =>
      dispatch({
        type: "RESET_ADDRESS_FORM",
      }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReschedulingsReport);
