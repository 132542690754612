import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Sidebar from '../../components/Navigation/Sidebar';
import Topbar from '../../components/Navigation/Topbar';
import { buttonIcon, buttonTypes } from "../../utils/buttonsUtil";
import TableListGuides from './TableListGuides';
import { useForm } from 'react-hook-form';
import { Button } from "react-bootstrap";

const BulkGuideReader = ({
  bulkGuideRead,
  reduxReadAllGuides,
  reduxResetBulkGuidesReader,
  reduxDownloadBulkGuideCSV,
}) => {

  const { register, handleSubmit, formState: { errors } } = useForm();
  const [inputValues, setInputValues] = useState([]);
  const [textareaValue, setTextareaValue] = useState("");
  const [errorGuideTextArea, setErrorGuideTextArea] = useState(false);
  const [errorGuideMessage, setErrorGuideMessage] = useState("");
  const [submittedGuideList, setSubmittedGuideList] = useState([]);

  useEffect(() => {
    if (inputValues.length > 0 && inputValues.length <= 500) {
      setErrorGuideTextArea(false);
      setErrorGuideMessage("");
      handleSubmit(onSubmit)();
    } else if (inputValues.length > 500) {
      setErrorGuideTextArea(true);
      setErrorGuideMessage("Límite de guías excedido: 500 máximo.");
      setInputValues([]);
    }
  }, [inputValues])

  useEffect(() => {
    return () => {
      reduxResetBulkGuidesReader();
    };
  }, [])
  
  const actionButtons = [
    {
      onClick: () => {
        if (bulkGuideRead && bulkGuideRead.foundAddresses.length > 0) {
          reduxDownloadBulkGuideCSV({ guideList: submittedGuideList });
        }
        console.log("Descargar CSV");
      },
      description: "Descargar guías",
      buttonType: buttonTypes.Success,
      buttonIcon: buttonIcon.DownloadFile,
    },
  ];

  const onChangeBulkGuide = (e) => {
    const value = e.target.value;
    setTextareaValue(value);

    if (value.includes("\n") || value.includes(",")) {
      let newValuesArray = value.split(/[\r?\n,]+/).map(val => val.trim()).filter(val => val !== "");
      const updatedValuesArray = [...inputValues, ...newValuesArray];
      setInputValues(updatedValuesArray);

      setTextareaValue("");
    }
  };

  const handleInputChange = (index, newValue) => {
    const updatedValues = [...inputValues];
    updatedValues[index] = newValue;
    setInputValues(updatedValues);
  };

  const handleRemoveInput = (index) => {
    const updatedValues = inputValues.filter((_, i) => i !== index);
    setInputValues(updatedValues);
  };

  const onSubmit = () => {
    if (inputValues.length < 1) {
      setErrorGuideTextArea(true);
      return;
    }
    setErrorGuideTextArea(false);
    setSubmittedGuideList(inputValues);
    reduxReadAllGuides({ guideList: inputValues });
    setInputValues([]);
  };

  return (
    <>
      <div id='wrapper'>
        <Sidebar />
        <div id='content-wrapper' className='d-flex flex-column'>
          <div id='content'>
            <Topbar />
            <div className='container-fluid'>
              <div className="d-sm-flex align-items-center my-2">
                <h1 className="h3 mb-0 text-gray-800 mr-3">Lector de guías</h1>
                <div className="mx-1">
                  {bulkGuideRead && (
                    <button
                      title="Regresar a lectura masiva de guías"
                      className={`btn ${buttonTypes.Primary} btn-circle`}
                      type="button"
                      onClick={() => reduxResetBulkGuidesReader()}
                    >
                      <i className={`${buttonIcon.Arrow_LeftReply}`}></i>
                    </button>
                  )}
                </div>
                {(bulkGuideRead && false) && (
                  <div>
                    {actionButtons.map((button, index) => (
                      <button
                        title='Descargar de guías'
                        key={index}
                        onClick={button.onClick}
                        className={`btn ${button.buttonType} btn-circle mx-1`}
                      >
                        <i className={button.buttonIcon}></i>
                      </button>
                    ))}
                  </div>
                )}
              </div>
              <p className='mb-4'>Módulo de lectura masiva de guías</p>

              <div className="card shadow mb-4">
                <div className='card-body'>
                  {bulkGuideRead ?
                    (
                      <TableListGuides />
                    ) :
                    (
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                          <div className="col-md-5">
                            <div className="form-group col-md-12">
                              <textarea
                                className={`form-control form-control-user ${errors.trackings && "is-invalid"}`}
                                placeholder={"Ingrese las guías separadas por coma o por salto de línea."}
                                rows={1}
                                value={textareaValue}
                                style={{ resize: 'none', width: '100%', minWidth: '500px' }}
                                onChange={onChangeBulkGuide}
                              />
                              {errorGuideTextArea && (
                                <span className={`text-danger`}>
                                  { errorGuideMessage }
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    bulkGuideRead: state.addressState.bulkGuideRead,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reduxReadAllGuides: (payload) =>
      dispatch({
        type: "BULK_GUIDE_READER_REQUEST",
        value: payload,
      }),
      reduxDownloadBulkGuideCSV: (payload) =>
        dispatch({
          type: "BULK_GUIDE_CSV_REQUEST",
          value: payload,
        }),
    reduxResetBulkGuidesReader: () =>
      dispatch({
        type: "BULK_GUIDE_READER_REQUESTING_RESET",
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkGuideReader);