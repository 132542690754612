const initialState = {
  requestingGetAllUserTypes: false,
  successGetAllUserTypes: false,
  errorGetAllUserTypes: false,
  allUserTypes: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_USER_TYPES_REQUESTING":
      return {
        ...state,
        requestingGetAllUserTypes: true,
        successGetAllUserTypes: false,
        errorGetAllUserTypes: false,
      };
    case "GET_ALL_USER_TYPES_SUCCESS":
      return {
        ...state,
        errorGetAllUserTypes: false,
        requestingGetAllUserTypes: false,
        successGetAllUserTypes: true,
        allUserTypes: action.value,
      };
    case "GET_ALL_USER_TYPES_ERROR":
      return {
        ...state,
        errorGetAllUserTypes: true,
        requestingGetAllUserTypes: false,
        successGetAllUserTypes: false,
      };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

export default userReducer;
